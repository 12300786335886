/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import { Registration } from './components/Registration'
import { ForgotPassword } from './components/ForgotPassword'
import { Login } from './components/Login'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (
    // <div
    //   className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
    //   style={{
    //     backgroundImage: `url(${process.env.REACT_APP_FOLDER_IMAGE}/auth-bg.png)`,
    //   }}
    // >
    //   {/* begin::Content */}
    //   <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
    //     {/* begin::Logo */}
    //     <a href='#' className='mb-12 d-flex align-items-center'>
    //       <img
    //         alt='Logo'
    //         src={`${process.env.REACT_APP_FOLDER_IMAGE}/default-small.png`}
    //         className='h-45px'
    //       />
    //       <h1 className='text-primary ms-2 text-truncate' style={{ marginTop: '10px' }}>
    //         {process.env.REACT_APP_NAME}
    //       </h1>
    //     </a>
    //     {/* end::Logo */}
    //     {/* begin::Wrapper */}
    //     <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
    //       <Outlet />
    //     </div>
    //     {/* end::Wrapper */}
    //   </div>
    //   {/* end::Content */}
    //   {/* begin::Footer */}
    //   <div className='d-flex flex-center flex-column-auto p-10'>
    //     <div className='d-flex align-items-center fw-bold fs-6'>
    //       <a href='#' className='text-muted text-hover-primary px-2'>
    //         About
    //       </a>

    //       <a href='#' className='text-muted text-hover-primary px-2'>
    //         Contact
    //       </a>

    //       <a href='#' className='text-muted text-hover-primary px-2'>
    //         Contact Us
    //       </a>
    //     </div>
    //   </div>
    //   {/* end::Footer */}
    // </div>
    <div className='row bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed m-0'>
      <div className='d-none d-lg-flex col-lg-8 p-0' style={{ height: '100vh' }}>
        <img
          src={`${process.env.REACT_APP_FOLDER_IMAGE}/auth-bg.png`}
          alt=''
          className='w-100'
          style={{ objectFit: 'cover' }}
        />
      </div>
      <div className='col-12 col-lg-4 p-0 overflow-scroll' style={{ maxHeight: '100vh' }}>
        {/* begin::Content */}
        <div className='d-flex flex-center flex-column flex-column-fluid flex-1 p-10'>
          {/* begin::Logo */}
          <div className='d-none d-xl-flex align-items-center'>
            <a href='#' className='me-2'>
              <img
                alt='Logo'
                src={`${process.env.REACT_APP_FOLDER_IMAGE}/default-small.png`}
                className='h-45px'
              />
            </a>
            <h1 className='text-primary m-0' style={{ fontSize: '2rem' }}>
              {process.env.REACT_APP_NAME}
            </h1>
          </div>

          {/* end::Logo */}
          {/* begin::Wrapper */}
          <div className='rounded px-10 pt-10 pb-0 px-lg-15 w-100 d-flex flex-1 align-items-center'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Content */}
        {/* begin::Footer */}
        {/* <div className='d-flex flex-center flex-column-auto p-10'>
          <div className='d-flex align-items-center fw-bold fs-6'>
            <a href='#' className='text-muted text-hover-primary px-2'>
              About
            </a>

            <a href='#' className='text-muted text-hover-primary px-2'>
              Contact
            </a>

            <a href='#' className='text-muted text-hover-primary px-2'>
              Contact Us
            </a>
          </div>
        </div> */}
        {/* end::Footer */}
      </div>
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export { AuthPage }

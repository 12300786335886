import { FC } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import { ChatbotRoutes } from './ChatbotRoutes'

export const ChatbotManagerWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'MENU.CHATBOT_MANAGER' })}</PageTitle>
      <ChatbotRoutes />
    </>
  )
}

import React from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap'
import { Formik, FormikHelpers, FormikValues } from 'formik'
import { createConversation } from '../util'
import { AlertProviderContext } from '../../alert/context'
import { ChatbotDetailContext } from './util'
import { ChatbotDetailActionType } from './util'
import { ConversationModel } from '../../_models'
import { AlertProviderActionType } from '../../alert/reducer'
import { AlertMessageType } from '../../alert/_models'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

export const NoConversation: React.FC = () => {
  const { dispatch: alertDispatch } = React.useContext(AlertProviderContext)
  const { conversations, dispatch } = React.useContext(ChatbotDetailContext)
  const { chatbotId } = useParams()
  const navigate = useNavigate()


  const [open, setOpen] = React.useState(false)
  const handleCreateConversation = (conversation: Partial<ConversationModel>) => {
    createConversation(conversation)
      .then((data) => {
        // alert success
        dispatch({
          type: ChatbotDetailActionType.CONVERSATION_ITEM,
          item: data,
        })
        navigate(`/chatbot/${chatbotId}/conversation/${data.id}`)
      })
      .catch((err) => {
        // alert failed
        alertDispatch({
          type: AlertProviderActionType.ADD_MESSAGE,
          item: {
            type: AlertMessageType.FAILED,
            message: `${err}`,
          },
        })
        console.log(err)
      })
  }

  return (
    <div className='flex-lg-row-fluid col-12 col-lg-10 mb-10 mb-lg-0 flex-center'>
      <div className='w-100 h-100 d-flex flex-column flex-center'>
      {/* <img src={toAbsoluteUrl('/media/svg/illustrations/empty-folder.png')} width={200} height={200} alt="" /> */}
      <img src={toAbsoluteUrl('/media/svg/illustrations/ofis_conversation.png')} width={250} height={250} alt="" />
        <button onClick={() => setOpen(true)} className='mt-2 btn btn-outline btn-outline-primary'>Create new conversation</button>
      </div>
      <Modal show={open} onHide={() => setOpen(false)} centered>
          <Modal.Header>
            <Modal.Title>New Conversation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{ conversation_name: uuidv4() } as Partial<ConversationModel>}
              validate={(values) => {
                const errors: any = {}
                if (values.conversation_name === '') {
                  errors.conversation_name = `Conversation name is required`
                }
                return errors
              }}
              onSubmit={(
                values: FormikValues,
                formikHelpers: FormikHelpers<FormikValues>
              ): void | Promise<any> => {
                setOpen(false)
                const conversation: Partial<ConversationModel> & { chatbot: number | string } = {
                  chatbot: chatbotId as string,
                  conversation_name: values.conversation_name,
                }
                handleCreateConversation(conversation)
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className='px-7 py-5'>
                    <div className='mb-10'>
                      <label className='form-label fw-bold'>Name:</label>
                      <div>
                        <input
                          type='text'
                          name='conversation_name'
                          className='form-control form-control-solid'
                          value={values.conversation_name}
                          onChange={handleChange}
                        />
                      </div>
                      {touched.conversation_name && errors.conversation_name && (
                        <div className='fv-plugins-message-container'>
                          <span role='alert' className='text-danger'>
                            {errors.conversation_name}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className='d-flex justify-content-end'>
                      <button
                        type='submit'
                        className='btn btn-sm btn-primary'
                        disabled={isSubmitting}
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>

    </div>
  )
}
import axios from 'axios'
import React from 'react'
import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { DropPopupMenu } from '../../partials/DropPopupMenu'
import { FileModel, ChatbotModel, BotConnectModel, MessageModel } from '../../_models'
import { deleteChatbot, updateChatbot, connectChatbot } from '../../../services/chatbot.service'
import { API_URL } from '../../../const'
import { SelectedFilesModalMemo } from '../../../../_metronic/partials/modals/selected-files/SelectedFilesModal'
import { Formik, FormikHelpers, FormikValues } from 'formik'
import { ChatbotManagerContext, ChatbotManagerActionType, getChatbotManagerContext } from '../util'
import { AlertProviderContext } from '../../alert/context'
import { AlertProviderActionType } from '../../alert/reducer'
import { AlertMessageType } from '../../alert/_models'
import { MenuComponent } from '../../../../_metronic/assets/ts/components/MenuComponent'
import { Switch } from "@material-ui/core";
import { alpha, styled } from '@mui/material/styles';
import theme from 'react';

type ChatbotRowState = {
  selectedFiles: {
    [key: string]: FileModel
  }
}

enum ChatbotRowActionType {
  SET_SELECTED_FILES = 'SET_SELECTED_FILES',
  TOGGLE_SELECT_FILE = 'TOGGLE_SELECT_FILE',
  SELECT_ALL_FILE = 'SET SELECT ALL FILE'
}

type ChatbotRowAction = {
  type: ChatbotRowActionType
} & { [key: string]: any }

const chatbotRowReducer = (state: ChatbotRowState, action: ChatbotRowAction): ChatbotRowState => {
  switch (action.type) {
    case ChatbotRowActionType.SET_SELECTED_FILES:
      return { ...state, selectedFiles: action.selectedFiles }
    case ChatbotRowActionType.TOGGLE_SELECT_FILE:
      return toggleSelectFile(state, action)
    case ChatbotRowActionType.SELECT_ALL_FILE: 
      return selectAllFile(state, action)
    default:
      return state
  }
}

const toggleSelectFile = (state: ChatbotRowState, action: ChatbotRowAction): ChatbotRowState => {
  const newState = { ...state }
  if (newState.selectedFiles.hasOwnProperty(action.item.id)) {
    delete newState.selectedFiles[action.item.id]
  } else {
    newState.selectedFiles[action.item.id] = action.item
  }
  return newState
}

const selectAllFile = (state: ChatbotRowState, action: ChatbotRowAction): ChatbotRowState => {
  const newState = { ...state }
  if (!newState.selectedFiles.hasOwnProperty(action.item.id)) {
    newState.selectedFiles[action.item.id] = action.item
  }
  return newState
}

export const ChatbotRow = ({ chatbot }: { chatbot: ChatbotModel }) => {
  const { dispatch: alertDispatch } = React.useContext(AlertProviderContext)
  const {
    bots,
    dispatch: chatbotManagerDispatch,
    socketRef,
    getChatbots,
  } = React.useContext(ChatbotManagerContext)
  const [state, dispatch] = React.useReducer(chatbotRowReducer, {
    selectedFiles: bots[chatbot.id].files,
  })

  React.useEffect(() => {
    dispatch({
      type: ChatbotRowActionType.SET_SELECTED_FILES,
      selectedFiles: bots[chatbot.id].files,
    })
    // MenuComponent.reinitialization()
  }, [bots[chatbot.id].files])

  const [showModalFile, setShowModalFile] = React.useState(false)
  const [showModalUpdate, setShowModalUpdate] = React.useState(false)
  const [showModalConnect, setShowModalConnect] = React.useState(false)
  const { selectedFiles } = state

  const ref = React.useRef<HTMLDivElement>(null)
  const arrowRef = React.useRef<HTMLSpanElement>(null)
  const menuRef = React.useRef<HTMLDivElement>(null)

  const handleDelete = (id: number) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this chatbot!',
      icon: 'warning',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteChatbot(id)
          if (response) {
            Swal.fire('Deleted!', 'Your chatbot has been deleted.', 'success')
          }
          chatbotManagerDispatch({
            type: ChatbotManagerActionType.DELETE_CHATBOT,
            botId: chatbot.id,
          })
          alertDispatch({
            type: AlertProviderActionType.ADD_MESSAGE,
            item: {
              type: AlertMessageType.SUCCESS,
              message: `Chatbot '${chatbot.bot_name}' deleted!`,
            },
          })
        } catch (error) {
          console.log(error)
          alertDispatch({
            type: AlertProviderActionType.ADD_MESSAGE,
            item: {
              type: AlertMessageType.FAILED,
              message: `${error}`,
            },
          })
        }
      }
      Swal.close()
    })
  }

  const renderActions = (chatbotId: number | string) => {
    return (
      <div className='d-flex flex-row align-items-center justify-content-start h-100'>
        <Link to={`/chatbot/${chatbotId}`}>
          <button className='btn btn-icon btn-sm bg-light-primary rounded-circle me-2'>
            <i className='bi bi-play fs-3 hoverable-element'></i>
          </button>
        </Link>
        {/* <a
          href='#'
          className='btn btn-light btn-active-light-primary btn-sm'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
        >
          Actions<i className='ki-duotone ki-down fs-5 m-0'></i>
        </a> */}
        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
          data-kt-menu='true'
        >
          <div className='menu-item px-3' onClick={() => setShowModalUpdate(true)}>
            <a className='menu-link px-3'>Update</a>
          </div>
          <div className='menu-item px-3' onClick={() => setShowModalConnect(true)}>
            <a className='menu-link px-3'>Connect Channels</a>
          </div>
          <div className='menu-item px-3' onClick={() => handleDelete(Number(chatbotId))}>
            <a className='menu-link px-3' data-kt-users-table-filter='delete_row'>
              Delete
            </a>
          </div>
        </div>
      </div>
    )
  }

  const clickSelectFile = (file: FileModel) => {
    dispatch({
      type: ChatbotRowActionType.TOGGLE_SELECT_FILE,
      item: file,
    })
  }

  const clickSelectAllFile = (files: FileModel[]) => {
    files.forEach(file => {
      dispatch(
        {
          type: ChatbotRowActionType.SELECT_ALL_FILE,
          item: file,
        }
      )
    })
  }

  const handleSaveChanges = () => {
    setShowModalFile(false)
    // update frontend immediately
    chatbotManagerDispatch({
      type: ChatbotManagerActionType.UPDATE_SELECTED_FILES,
      chatbotId: chatbot.id,
      files: selectedFiles,
    })

    // update backend
    const fileIds = Object.keys(selectedFiles)
    socketRef!.current!.emit('update-selected-files', { chatbot_id: chatbot.id, file_ids: fileIds })
  }

  const initValues: Partial<ChatbotModel> = {
    bot_name: chatbot.bot_name,
    language: chatbot.language,
    system_prompt: chatbot.system_prompt || '',
    use_system_prompt: chatbot.use_system_prompt,
  }

  const connectValues: Partial<BotConnectModel> = {
    bot_name: chatbot.bot_name,
    channel: 'Telegram',
    bot_token: '',
  }

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#DD5151',
      '&:hover': {
        backgroundColor: alpha('#DD5151', theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#DD5151',
    },
  }));

  return (
    <tr>
      <td className='align-middle '>
        <div className='d-flex flex-row align-items-center justify-content-center h-100'>  
          <img
            alt='Logo'
            src={('/media/cmc/OFIS-Logo-Small.png')}
            className='h-20px h-lg-30px app-sidebar-logo-default theme-light-show'
          />
        </div>
      </td>
      <td className='align-middle'>
        <Link to={`/chatbot/${chatbot.id}`}>{chatbot.bot_name}</Link>
      </td>
      {/* <td className='align-middle'>{chatbot.system_prompt}</td> */}
      <td></td>
      <td></td>
      {/* <td className='align-middle'>
        <div className='menu-item fw-semibold'>
          <a
            href='#'
            className='btn btn-light-primary btn-sm'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            Selected Files<i className='ki-duotone ki-down fs-5 m-0'></i>
          </a>
          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4'
            data-kt-menu='true'
          >
            <div className='mx-3'>
              {Object.values(selectedFiles).map((file) => {
                return (
                  <span key={file.id} className='menu-link pt-3 text-truncate'>
                    <span
                      className='menu-title file-item span-overflow-ellipsis d-block'
                      title={file.file_name}
                    >
                      {file.file_name}
                    </span>
                  </span>
                )
              })}
              <div className='separator my-1'></div>
              <div className='d-flex justify-content-center'>
                <button className='btn btn-icon pb-0 w-100' onClick={() => setShowModalFile(true)}>
                  <i className='bi bi-plus-circle-dotted fs-1 hoverable-element'></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </td> */}
      <td className='align-middle'>{renderActions(chatbot.id)}</td>

      <Modal show={showModalUpdate} centered onHide={() => setShowModalUpdate(false)}>
        <Modal.Header closeButton>
          <div>
            <Modal.Title>Update Chatbot</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initValues}
            validate={(values) => {
              const errors: any = {}
              if (values.bot_name === '') {
                errors.bot_name = `Chatbot 's name is required`
              }
              return errors
            }}
            onSubmit={async function (
              values: FormikValues,
              formikHelpers: FormikHelpers<FormikValues>
            ): Promise<void | Promise<any>> {
              formikHelpers.setSubmitting(true)
              try {
                const data = await updateChatbot(Number(chatbot.id), values)
                if (data) {
                  formikHelpers.setSubmitting(false)
                  setShowModalUpdate(false)
                  getChatbots()
                  Swal.fire('Updated!', 'Your chatbot has been updated.', 'success')
                }
              } catch (error) {
                formikHelpers.setSubmitting(false)
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div className='px-7 py-5'>
                  <div className='mb-10'>
                    <label className='form-label fw-bold'>Name:</label>
                    <div>
                      <input
                        type='text'
                        name='bot_name'
                        className='form-control form-control-solid'
                        value={values.bot_name}
                        onChange={handleChange}
                      />
                    </div>
                    {touched.bot_name && errors.bot_name && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert' className='text-danger'>
                          {errors.bot_name}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className='mb-0' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <label className='form-label fw-bold'>System prompt:</label>
                      <CustomSwitch
                        name="use_system_prompt"
                        color="primary"
                        value={`${values.use_system_prompt}`}
                        checked={values.use_system_prompt}
                        onChange={(event, checked) => {
                          setFieldValue("use_system_prompt", checked);
                        }}
                      />
                    </div>
                    
                    <div className='mb-10'>
                      <div>
                      <input
                        type='text'
                        name='system_prompt'
                        className='form-control form-control-solid'
                        value={values.system_prompt}
                        onChange={handleChange}
                        disabled={!values.use_system_prompt}
                        style={{ backgroundColor: !values.use_system_prompt ? '#D9D9D9' : '' }}
                      />
                    </div>
                  </div>

                  <div className='mb-10'>
                    <label className='form-label fw-bold'>Language:</label>

                    <div className='d-flex'>
                      <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          value='vi'
                          name='language'
                          onChange={handleChange}
                          defaultChecked={initValues.language === 'vi'}
                        />
                        <span className='form-check-label'>Vietnamese</span>
                      </label>

                      <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          value='en'
                          name='language'
                          onChange={handleChange}
                          defaultChecked={initValues.language === 'en'}
                        />
                        <span className='form-check-label'>English</span>
                      </label>

                      <label className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='radio'
                          value='auto'
                          name='language'
                          onChange={handleChange}
                          defaultChecked={initValues.language === 'auto'}
                        />
                        <span className='form-check-label'>Auto</span>
                      </label>
                    </div>
                  </div>

                  {/* <div className='mb-10 d-flex flex-row'>
                    <label className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='use_system_prompt'
                        onChange={handleChange}
                        value={`${values.use_system_prompt}`}
                        defaultChecked={initValues.use_system_prompt}
                      />
                    </label>
                    <label className='form-label fw-bold ms-2 my-0'>Use system prompt</label>
                  </div> */}

                  <div className='d-flex justify-content-end'>
                    <button
                      type='submit'
                      className='btn btn-sm btn-primary'
                      data-kt-menu-dismiss='true'
                      disabled={isSubmitting}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      <Modal show={showModalConnect} centered onHide={() => setShowModalConnect(false)}>
        <Modal.Header closeButton>
          <div>
            <Modal.Title>Connect Channels</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={connectValues}
            validate={(values) => {
              const errors: any = {}
              if (values.bot_token === '') {
                errors.bot_token = `Chatbot 's token is required`
              }
              return errors
            }}
            onSubmit={async function (
              values: FormikValues,
              formikHelpers: FormikHelpers<FormikValues>
            ): Promise<void | Promise<any>> {
              formikHelpers.setSubmitting(true)
              try {
                const data = await connectChatbot(Number(chatbot.id), values)
                if (data) {
                  formikHelpers.setSubmitting(false)
                  setShowModalConnect(false)
                  getChatbots()
                  Swal.fire('Success!', data.message, 'success')
                }
              } catch (error) {
                formikHelpers.setSubmitting(false)
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div className='px-7 py-5'>
                  <div className='mb-10'>
                    <label className='form-label fw-bold'>Name:</label>
                    <div>
                      <input
                        type='text'
                        name='bot_name'
                        disabled={true}
                        className='form-control form-control-solid'
                        value={values.bot_name}
                      />
                    </div>
                  </div>

                  <div className='mb-10'>
                    <label className='form-label fw-bold'>Channel:</label>

                    <div className='d-flex'>
                      <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                        <input
                          className='form-check-input'
                          type='radio'
                          value='Telegram'
                          name='channel'
                          onChange={handleChange}
                          defaultChecked={connectValues.channel === 'Telegram'}
                        />
                        <span className='form-check-label'>Telegram</span>
                      </label>

                      <label className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='radio'
                          value='Skype'
                          name='channel'
                          onChange={handleChange}
                          defaultChecked={connectValues.channel === 'Skype'}
                        />
                        <span className='form-check-label'>Skype</span>
                      </label>
                    </div>
                  </div>

                  <div className='mb-10'>
                    <label className='form-label fw-bold'>Enter the access key (bot token):</label>
                    <div>
                      <input
                        type='text'
                        name='bot_token'
                        className='form-control form-control-solid mb-5'
                        value={values.bot_token}
                        onChange={handleChange}
                      />
                    </div>
                    <a href='https://core.telegram.org/bots#how-do-i-create-a-bot' target='_blank'>
                      How do I get the bot's token?
                    </a>
                  </div>
                  <div className='d-flex justify-content-end'>
                    <button
                      type='submit'
                      className='btn btn-sm btn-primary'
                      data-kt-menu-dismiss='true'
                      disabled={isSubmitting}
                    >
                      Connect
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      {showModalFile && (
        <SelectedFilesModalMemo
          showModalFile={showModalFile}
          setShowModalFile={setShowModalFile}
          selectedFiles={selectedFiles}
          clickSelectFile={clickSelectFile}
          handleSaveChanges={handleSaveChanges}
          clickSelectAllFile={clickSelectAllFile}
        ></SelectedFilesModalMemo>
      )}
    </tr>
  )
}

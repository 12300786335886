import React from 'react'
import clsx from 'clsx'
import { ConversationDetailContext, ConversationDetailActionType } from './util'
import { useIntl } from 'react-intl'
import { ExtendedHighlightArea, ReferenceModel } from '../../_models'

export const References = () => {
  const intl = useIntl()
  const { state, dispatch, highlightPluginInstance } = React.useContext(ConversationDetailContext)

  const handleClickReference = (e: any, item: ReferenceModel, idx: number) => {
    if (!item.can_highlight) return
    if (idx !== state.selectedReference) {
      dispatch({
        type: ConversationDetailActionType.SET_SELECTED_REFERENCE,
        idx: idx,
      })
    }
    const referenceFileId = state.references[idx].fileId
    if (referenceFileId == state.selectedFileId) {
      // jump to highlight area
      if (state.references[idx].highlights.length > 0) {
        highlightPluginInstance!.jumpToHighlightArea(state.references[idx].highlights[0])
      }
    } else {
      dispatch({
        type: ConversationDetailActionType.TRIGGER_HIGHLIGHT_ANOTHER_FILE,
        selectedFileId: state.references[idx].fileId,
      })
    }
  }

  if (state.references.length === 0 || state.showReferences === false) return null

  return (
    <div className='d-flex flex-column justify-content-start, align-items-stretch px-5'>
      <div className='fw-bold'>{intl.formatMessage({ id: 'CHAT_MESSAGE.REFERENCES' })}</div>
      <div className='px-4'>
        {state.references.map((item, idx) => {
          return (
            <div className='mt-1' key={idx}>
              <div
                className={clsx(
                  { 'text-primary text-decoration-underline': idx === state.selectedReference },
                  'hoverable-element p-3 rounded position-relative'
                )}
              >
                <span
                  className='d-block span-overflow-ellipsis fw-semibold'
                  onClick={(e) => handleClickReference(e, item, idx)}
                >
                  {item.text}
                </span>
                {/* <i
                  className='bi bi-hand-thumbs-up-fill fs-6 text-warning position-absolute'
                  style={{ bottom: '-5px', left: '10px' }}
                ></i> */}
              </div>
              {/* <div className='w-100 d-flex align-items-center justify-content-end'>
                <DropdownReactMessage show={refSelected === idx} reaction={Reaction.NONE} />
                <button className='btn btn-icon btn-sm fs-6'>
                  <i className='bi bi-three-dots-vertical fs-2'></i>
                </button>
              </div> */}
            </div>
          )
        })}
      </div>
    </div>
  )
}

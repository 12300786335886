/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import {
  conversationDetailReducer,
  initConversationDetailState,
  ConversationDetailActionType,
  ConversationDetailContext,
  ChatbotDetailActionType,
} from './util'
import { ChatbotDetailContext, ChatStatus } from './util'
import { ChatbotManagerContext } from '../util'
import { MessageController } from './MessageController'
import PDFReader from '../../../modules/apps/chat/components/PDFViewer/PDFReader'
import TopBarProgress from 'react-topbar-progress-indicator'
import {
  RenderHighlightTargetProps,
  RenderHighlightsProps,
  highlightPlugin,
} from '@react-pdf-viewer/highlight'
import { LLMType, updateConversationLLMType } from '../util'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { AlertProviderContext } from '../../alert/context'
import { AlertProviderActionType } from '../../alert/reducer'
import { AlertMessageType } from '../../alert/_models'
import { ErrorPage } from '../../partials/ErrorPage'

export const ConversationDetail: React.FC = () => {
  const navigate = useNavigate()
  const { dispatch: alertDispatch } = React.useContext(AlertProviderContext)
  const { socketRef, bots } = React.useContext(ChatbotManagerContext)
  const { conversations, dispatch: chatbotDetailDispatch } = React.useContext(ChatbotDetailContext)
  const { chatbotId, conversationId } = useParams()

  // derivatives of context variables
  const conversation = conversationId ? conversations[conversationId] : null
  const selectedFiles = bots[chatbotId as string].files
  const initSelectedFileId =
    Object.keys(selectedFiles).length > 0 ? Object.keys(selectedFiles)[0] : -1

  const paramRef = React.useRef<{ [key: string]: any }>({ chatbotId, conversationId })
  const [state, dispatch] = React.useReducer(conversationDetailReducer, {
    ...initConversationDetailState,
    selectedFileId: initSelectedFileId,
  })

  React.useEffect(() => {
    const onListMessageReady = (data: any): void => {
      dispatch({
        type: ConversationDetailActionType.LIST_MESSAGE_READY,
        messages: data,
      })
    }
    socketRef!.current!.on('list-message-ready', onListMessageReady)

    const onMessageItem = (item: any): void => {
      dispatch({
        type: ConversationDetailActionType.MESSAGE_ITEM,
        item: {
          ...item,
          references: item.references.map((ref: any) => ({
            ...ref,
            fileId: ref.file.id,
            file: ref.file,
          })),
        },
      })
    }
    socketRef!.current!.on('message-item', onMessageItem)

    const onChunkReceived = (data: any): void => {
      dispatch({
        type: ConversationDetailActionType.CHUNK_RECEIVED,
        chunk: data,
      })
      if (state.chatStatus !== ChatStatus.MESSAGE_RUNNING) {
        dispatch({
          type: ConversationDetailActionType.SET_CHAT_STATUS,
          chatStatus: ChatStatus.MESSAGE_RUNNING,
        })
      }
    }
    socketRef!.current!.on('chunk-received', onChunkReceived)

    const onAnswerDone = (data: any): void => {
      dispatch({
        type: ConversationDetailActionType.SAVE_BOT_MESSAGE,
        item: data,
      })
      dispatch({
        type: ConversationDetailActionType.SHOW_REFERENCES,
      })
    }
    socketRef!.current!.on('answer-done', onAnswerDone)

    const onSaveUserMessage = (data: any): void => {
      dispatch({
        type: ConversationDetailActionType.SAVE_USER_MESSAGE,
        item: data,
      })
      socketRef!.current!.emit('ask-question', {
        question: data.content,
        chatbot_id: paramRef.current.chatbotId,
        conversation_id: paramRef.current.conversationId,
      })
    }
    socketRef!.current!.on('save-user-message', onSaveUserMessage)

    const onReferences = (data: any): void => {
      dispatch({
        type: ConversationDetailActionType.SET_REFERENCES,
        references: data,
      })
    }
    socketRef!.current!.on('set-references', onReferences)

    const onGenerativeError = (data: any): void => {
      alertDispatch({
        type: AlertProviderActionType.ADD_MESSAGE,
        item: {
          type: AlertMessageType.FAILED,
          message: data,
        },
      })
    }
    socketRef!.current!.on('generative-error', onGenerativeError)

    return () => {
      socketRef!.current!.off('list-message-ready', onListMessageReady)
      socketRef!.current!.off('message-item', onMessageItem)
      socketRef!.current!.off('chunk-received', onChunkReceived)
      socketRef!.current!.off('answer-done', onAnswerDone)
      socketRef!.current!.off('save-user-message', onSaveUserMessage)
      socketRef!.current!.off('set-references', onReferences)
      socketRef!.current!.off('generative-error', onGenerativeError)
    }
  }, [])

  React.useEffect(() => {
    paramRef.current.conversationId = conversationId
    dispatch({
      type: ConversationDetailActionType.RESET_CONVERSATION_STATE,
    })
    socketRef!.current!.emit('message-list', { conversation_id: conversationId })
  }, [conversationId])
  // }, [conversationId, chatbotId]) No need to include chatbotId here since changing chatbotId remounts this component

  const openGoogleWithKeyword = (keyword: string) => {
    const searchUrl = `https://www.google.com/search?q=${encodeURIComponent('define:' + keyword)}`
    window.open(searchUrl, '_blank')
  }

  const renderHighlightTarget = (props: RenderHighlightTargetProps) => (
    <div
      style={{
        background: '#eee',
        display: 'flex',
        position: 'absolute',
        left: `${props.selectionRegion.left}%`,
        top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
        transform: 'translate(0, 8px)',
        zIndex: 1,
      }}
    >
      <div
        className='bg-white'
        style={{
          boxShadow:
            'rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px',
        }}
      >
        <div className='p-1 d-flex align-items-center'>
          <button
            onClick={() => openGoogleWithKeyword(props.selectedText)}
            className='btn btn-icon btn-sm btn-w btn-active-light-primary me-2'
          >
            <i className='bi bi-search fs-3'></i>
          </button>

          <input
            type='text'
            className='form-control form-control-sm'
            placeholder='Ask a question'
            onKeyDown={(e) => {
              if (e.key === 'Enter') openGoogleWithKeyword(props.selectedText)
            }}
          />
        </div>

        {/* <button onClick={props.toggle}>search google</button> */}
      </div>
    </div>
  )

  const renderHighlights = (props: RenderHighlightsProps) => (
    <div>
      {state.references &&
        state.selectedReference >= 0 &&
        state.references[state.selectedReference].highlights
          .filter((area) => area.pageIndex === props.pageIndex)
          .map((area, idx) => (
            <div
              key={idx}
              style={Object.assign(
                {},
                {
                  background: '#219cd7',
                  opacity: 0.4,
                },
                props.getCssProperties(area, props.rotation)
              )}
            ></div>
          ))}
    </div>
  )

  const highlightPluginInstance = highlightPlugin({ renderHighlightTarget, renderHighlights })

  const handleChangeLLMType = (llm_type: any) => {
    chatbotDetailDispatch({
      type: ChatbotDetailActionType.UPDATE_CONVERSATION_LLM_TYPE,
      payload: {
        conversationId,
        llmType: llm_type,
      },
    })
    updateConversationLLMType({ ...conversation, llm_type })
  }

  const render = () => {
    if (conversation == null)
      return <Navigate to={`/chatbot/${chatbotId}/conversation/not_found`} replace={true} />
    if (state.loading) return <TopBarProgress />
    return (
      <ConversationDetailContext.Provider value={{ state, dispatch, highlightPluginInstance }}>
        <div className='flex-lg-row-fluid col-12 col-lg-5 mb-10 mb-lg-0 h-100'>
          <div className='card h-100 d-flex flex-column'>
            <div className='card-header'>
              <div className='card-title w-100'>
                <div className='symbol-group symbol-hover'></div>
                <div className='d-flex justify-content-between align-items-center w-100'>
                  <div className='d-flex'>
                    <select
                      value={chatbotId}
                      className='form-select w-auto mh-45px'
                      aria-label='Select a chatbot'
                      onChange={(e) => navigate(`/chatbot/${e.target.value}/conversation`)}
                    >
                      {Object.values(bots).map((item) => (
                        <option key={item.bot.id} value={`${item.bot.id}`}>
                          {item.bot.bot_name}
                        </option>
                      ))}
                    </select>
                    {/* <button className='btn btn-light-primary ms-2 mh-45px text-truncate'>
                      <i className='bi bi-upload'></i>
                      <span>Connect file</span>
                    </button> */}
                  </div>
                  <div className='dropdown'>
                    <a
                      className='dropdown-toggle btn btn-light d-flex flex-center'
                      href='#'
                      role='button'
                      data-bs-toggle='dropdown'
                    >
                      <img
                        src={toAbsoluteUrl(
                          conversation.llm_type === LLMType.CMC
                            ? '/media/logos/cmc.png'
                            : conversation.llm_type === LLMType.ChatGPT
                            ? '/media/logos/chatgpt.png'
                            : conversation.llm_type === LLMType.Mistral
                            ? '/media/logos/mistral.png'
                            : '/media/logos/gemini.png'
                        )}
                        width={conversation.llm_type === LLMType.CMC ? 25 : 35}
                        className='me-2'
                      />
                      {conversation.llm_type}
                    </a>
                    <ul className='dropdown-menu px-2'>
                      <li
                        className='row py-1 bg-hover-light cursor-pointer'
                        onClick={() => handleChangeLLMType(LLMType.ChatGPT)}
                      >
                        <img src={toAbsoluteUrl('/media/logos/chatgpt.png')} className='col-4' />
                        <span className='col-8'>ChatGPT</span>
                      </li>
                      <li
                        className='row py-1 bg-hover-light cursor-pointer'
                        onClick={() => handleChangeLLMType(LLMType.CMC)}
                      >
                        <div className='col-4 d-flex flex-center'>
                          <img src={toAbsoluteUrl('/media/logos/cmc.png')} width={25} />
                        </div>
                        <span className='col-8'>CMC</span>
                      </li>
                      <li
                        className='row py-1 bg-hover-light cursor-pointer'
                        onClick={() => handleChangeLLMType(LLMType.Mistral)}
                      >
                        <img src={toAbsoluteUrl('/media/logos/mistral.png')} className='col-4' />
                        <span className='col-8'>Mistral</span>
                      </li>
                      <li
                        className='row py-1 bg-hover-light cursor-pointer'
                        onClick={() => handleChangeLLMType(LLMType.Gemini)}
                      >
                        <img src={toAbsoluteUrl('/media/logos/gemini.png')} className='col-4' />
                        <span className='col-8'>Gemini</span>
                      </li>
                    </ul>
                  </div>
                  {/* <div className='d-flex'>
                    <button
                      className='btn btn-sm btn-icon btn-active-light-primary'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                    >
                      <i className='bi bi-bookmarks fs-3'></i>
                    </button>
                    <button
                      className='btn btn-sm btn-icon btn-active-light-primary ms-2'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                    >
                      <i className='bi bi-download fs-3'></i>
                    </button>
                    <button
                      className='btn btn-sm btn-icon btn-active-light-primary ms-2'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                    >
                      <i className='bi bi-file-earmark fs-3 position-relative'>
                        <i
                          className='bi bi-arrow-right-short position-absolute'
                          style={{ left: '2px', bottom: '1px' }}
                        ></i>
                      </i>
                    </button>
                    <button
                      className='btn btn-sm btn-icon btn-active-light-primary ms-2'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                    >
                      <i className='bi bi-question-circle fs-3'></i>
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
            <MessageController />
          </div>
        </div>
        {/* <div className='col-12 col-lg-5 mb-10 mb-lg-0 h-100 p-0'>
          {Object.keys(selectedFiles).length > 0 ? (
            <div className='card h-100'>
              <PDFReader file={selectedFiles[state.selectedFileId]} selectedFiles={selectedFiles} />
            </div>
          ) : (
            // <ErrorPage error='Not connected any files yet' />
            <div className='d-flex flex-column align-items-center justify-content-center w-100 h-100'>
              <div className='w-100 h-100 d-flex flex-column flex-center'>
                <img src={toAbsoluteUrl('/media/svg/illustrations/file.png')} width={200} height={200} alt="" />
                <button className='mt-2 btn btn-outline btn-outline-primary'>Connect File</button>
              </div>
            </div>
          )}
        </div> */}
      </ConversationDetailContext.Provider>
    )
  }

  return render()
}

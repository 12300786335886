import clsx from 'clsx'
import React, { MouseEventHandler } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { v4 as uuidv4 } from 'uuid'
import { WithChildren, checkConversationIsActive } from '../../../../_metronic/helpers'
import { KTIcon } from '../../../../_metronic/helpers'
import { ChatbotDetailContext } from './util'
import { ChatbotDetailActionType } from './util'
import { createConversation } from '../util'
import { ConversationModel } from '../../_models'
import { Modal } from 'react-bootstrap'
import { Formik, FormikHelpers, FormikValues } from 'formik'
import { AlertProviderContext } from '../../alert/context'
import { AlertProviderActionType } from '../../alert/reducer'
import { AlertMessageType } from '../../alert/_models'
import { deleteAConversation, updateAConversation } from '../../../services/conversation.service'
import Swal from 'sweetalert2'

type ConversationActionProps = {
  onEdit: boolean
  setOnEdit: Function
  handleEdit: MouseEventHandler
  handleDelete: MouseEventHandler
  handleSaveChange: MouseEventHandler
  handleCancelChange: MouseEventHandler
}

const ConversationAction: React.FC<ConversationActionProps> = ({
  onEdit,
  handleEdit,
  handleDelete,
  handleSaveChange,
  handleCancelChange,
}) => {
  return (
    <div>
      {onEdit && (
        <div className='d-flex flex-row justify-content-center'>
          <i className='bi bi-check2 fs-3 inherited-icon' onClick={handleSaveChange}></i>
          <i className='bi bi-x fs-3 inherited-icon' onClick={handleCancelChange}></i>
        </div>
      )}

      <i
        style={{
          display: onEdit ? 'none' : 'initial',
        }}
        className='bi bi-three-dots-vertical fs-3 menu-icon'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='bottom-end'
      ></i>
      <div
        className='menu-sub menu-sub-dropdown w-lg-125px w-100px droppop-menu'
        data-kt-menu='true'
      >
        <div className='menu-link' onClick={handleEdit}>
          Edit
        </div>
        <div className='menu-link' onClick={handleDelete}>
          Delete
        </div>
      </div>
    </div>
  )
}

type ConversationItemProps = {
  id: string | number
  to: string
  title: string
}

const ConversationItem: React.FC<ConversationItemProps> = ({ id, to, title }) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { dispatch: alertDispatch } = React.useContext(AlertProviderContext)
  const { dispatch: chatbotDetailDispatch } = React.useContext(ChatbotDetailContext)
  const isActive = checkConversationIsActive(pathname, to)
  const [value, setValue] = React.useState<string>(title)
  const [prevValue, setPrevValue] = React.useState<string>(title)
  const [onEdit, setOnEdit] = React.useState<boolean>(false)
  const inputField = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    setValue(title)
    setPrevValue(title)
  }, [title])

  const handleEdit = (e: any) => {
    e.stopPropagation()
    setOnEdit(true)
    if (inputField.current) {
      inputField.current.focus()
      inputField.current.setSelectionRange(0, inputField.current.value.length)
    }
  }

  const handleSaveChange = (e: any) => {
    e.stopPropagation()
    setPrevValue(value)
    setOnEdit(false)
    updateAConversation(id, { conversation_name: value })
      .then(() => {
        alertDispatch({
          type: AlertProviderActionType.ADD_MESSAGE,
          item: {
            type: AlertMessageType.SUCCESS,
            message: 'Successfully update conversation name',
          },
        })
      })
      .catch((err) => {
        alertDispatch({
          type: AlertProviderActionType.ADD_MESSAGE,
          item: {
            type: AlertMessageType.FAILED,
            message: `${err}`,
          },
        })
      })
  }

  const handleDelete = (e: any) => {
    e.stopPropagation()
    Swal.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this conversation!',
      icon: 'warning',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        // frontend immediate update
        chatbotDetailDispatch({
          type: ChatbotDetailActionType.DELETE_CONVERSATION_ITEM,
          conversationId: id
        })

        // backend update
        deleteAConversation(id).then(() => {
          alertDispatch({
            type: AlertProviderActionType.ADD_MESSAGE,
            item: {
              type: AlertMessageType.SUCCESS,
              message: `Conversation '${value}' deleted`,
            },
          })
        }).catch((err) => {
          alertDispatch({
            type: AlertProviderActionType.ADD_MESSAGE,
            item: {
              type: AlertMessageType.FAILED,
              message: `${err}`,
            },
          })
        })
      }
    })
  }

  const handleChangeValue = (e: any) => {
    e.stopPropagation()
    setValue(e.target.value)
  }

  const handleCancelChange = (e: any) => {
    e.stopPropagation()
    setValue(prevValue)
    setOnEdit(false)
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') handleSaveChange(e)
    else if (e.key === 'Escape') handleCancelChange(e)
  }

  return (
    <div className='menu-item'>
      <div
        className={clsx('menu-link without-sub w-100', {
          active: isActive,
        })}
        onClick={(e) => navigate(to)}
      >
        <i className='bi bi-chat menu-icon'></i>
        <input
          ref={inputField}
          className={clsx(
            'd-inline-block text-truncate me-2 bg-transparent border-0 flex-row-fluid',
            !onEdit && 'cursor-pointer'
          )}
          value={value}
          onChange={handleChangeValue}
          onKeyDown={handleKeyDown}
          readOnly={!onEdit}
          style={{ color: 'inherit', fontWeight: 'inherit', outline: 'none' }}
          spellCheck={false}
        />
        <ConversationAction
          onEdit={onEdit}
          setOnEdit={setOnEdit}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handleSaveChange={handleSaveChange}
          handleCancelChange={handleCancelChange}
        />
      </div>
    </div>
  )
}

export const ConversationList: React.FC = () => {
  const { dispatch: alertDispatch } = React.useContext(AlertProviderContext)
  const { chatbotId } = useParams()
  const { conversations, dispatch } = React.useContext(ChatbotDetailContext)
  const [searchQuery, setSearchQuery] = React.useState<string>('')
  const [open, setOpen] = React.useState(false)
  const timeoutRef = React.useRef<any>(undefined)
  const navigate = useNavigate()

  const handleChangeSearchQuery = (value: string) => {
    clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      setSearchQuery(value)
    }, 100)
  }

  const handleCreateConversation = (conversation: Partial<ConversationModel>) => {
    createConversation(conversation)
      .then((data) => {
        // alert success
        dispatch({
          type: ChatbotDetailActionType.CONVERSATION_ITEM,
          item: data,
        })
        navigate(`/chatbot/${chatbotId}/conversation/${data.id}`)
      })
      .catch((err) => {
        // alert failed
        alertDispatch({
          type: AlertProviderActionType.ADD_MESSAGE,
          item: {
            type: AlertMessageType.FAILED,
            message: `${err}`,
          },
        })
        console.log(err)
      })
  }

  return (
    <div className='flex-column flex-lg-row-auto col-12 col-lg-2 mb-10 mb-lg-0 h-100 p-0'>
      <div className='card card-flush h-100'>
        <div className='card-header ps-3 pe-5 pt-7 row' id='kt_chat_contacts_header'>
          <div className='col-10'>
            <form className='position-relative' autoComplete='off'>
              <KTIcon
                iconName='magnifier'
                className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 ms-1 translate-middle-y'
              />

              <input
                type='text'
                className='form-control form-control-solid px-7'
                name='search'
                placeholder='Search conversation'
                onChange={(e) => handleChangeSearchQuery(e.target.value)}
              />
            </form>
          </div>
          <div className='col-2 p-0'>
            <button
              className='btn btn-light-primary btn-icon btn-icon-sm w-100'
              onClick={() => setOpen(true)}
            >
              <FontAwesomeIcon icon={icon({ name: 'plus' })} />
              {/* <span>&nbsp;New</span> */}
            </button>
          </div>
        </div>

        <Modal show={open} onHide={() => setOpen(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>New Conversation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{ conversation_name: uuidv4() } as Partial<ConversationModel>}
              validate={(values) => {
                const errors: any = {}
                if (values.conversation_name === '') {
                  errors.conversation_name = `Conversation name is required`
                }
                return errors
              }}
              onSubmit={(
                values: FormikValues,
                formikHelpers: FormikHelpers<FormikValues>
              ): void | Promise<any> => {
                setOpen(false)
                const conversation: Partial<ConversationModel> & { chatbot: number | string } = {
                  chatbot: chatbotId as string,
                  conversation_name: values.conversation_name,
                }
                handleCreateConversation(conversation)
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className='px-7 py-5'>
                    <div className='mb-10'>
                      <label className='form-label fw-bold'>Name:</label>
                      <div>
                        <input
                          type='text'
                          name='conversation_name'
                          className='form-control form-control-solid'
                          value={values.conversation_name}
                          onChange={handleChange}
                        />
                      </div>
                      {touched.conversation_name && errors.conversation_name && (
                        <div className='fv-plugins-message-container'>
                          <span role='alert' className='text-danger'>
                            {errors.conversation_name}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className='d-flex justify-content-end'>
                      <button
                        type='submit'
                        className='btn btn-sm btn-primary'
                        disabled={isSubmitting}
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>

        <div className='pt-5'>
          <div
            className='scroll-y me-n5 h-200px h-lg-auto w-100'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_app_header, #kt_chat_contacts_header'
            data-kt-scroll-offset='0px'
          >
            <div className='menu menu-column menu-rounded ps-3 menu-gray-600 menu-active-bg-primary menu-hover-bg-light-primary fw-semibold'>
              {Object.values(conversations)
                .filter((item) =>
                  item.conversation_name!.toLowerCase().includes(searchQuery.toLowerCase())
                )
                .sort((a, b) => (b.id as number) - (a.id as number))
                .map((item) => {
                  return (
                    <ConversationItem
                      key={item.id}
                      id={item.id}
                      to={`/chatbot/${chatbotId}/conversation/${item.id}`}
                      title={item.conversation_name || `${item.id}`}
                    />
                  )
                })}
              {Object.values(conversations)
                .filter((item) =>
                  item.conversation_name!.toLowerCase().includes(searchQuery.toLowerCase())
                )
                .length === 0 && (
                <span className="d-flex flex-center form-control-solid px-7" style={{fontSize: "1.1rem",
                  fontWeight: 500}}>No conversation</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

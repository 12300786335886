import axios from 'axios'
import { ConversationModel } from '../custom/_models'
import { API_URL } from '../const'

export const updateAConversation = async (
  conversationId: number | string,
  dataUpdate: Partial<ConversationModel>
): Promise<ConversationModel> => {
  const formData = new FormData()
  for (const [key, value] of Object.entries(dataUpdate)) {
    formData.append(key, value as string)
  }
  const { data } = await axios.patch(`${API_URL}/conversation/${conversationId}`, formData)
  return data
}

export const deleteAConversation = async (conversationId: number | string) => {
  await axios.delete(`${API_URL}/conversation/${conversationId}`)
}
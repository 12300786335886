import React, { useEffect } from 'react'
import { ChatbotRow } from './ChatbotRow'
import { Button, Modal } from 'react-bootstrap'
import { Formik, FormikHelpers, FormikValues } from 'formik'
import { ChatbotModel, FileModel } from '../../_models'
import { KTIcon } from '../../../../_metronic/helpers'
import { DropdownChatbot } from '../../../../_metronic/partials/content/dropdown/DropdownChatbot'
import { SelectedFilesModalMemo } from '../../../../_metronic/partials/modals/selected-files/SelectedFilesModal'
import { BotVerboseMapType, ChatbotManagerActionType, FileMapType, createChatbot } from '../util'
import { ChatbotManagerContext } from '../util'
import { AlertProviderContext } from '../../alert/context'
import { AlertProviderActionType } from '../../alert/reducer'
import { AlertMessageType } from '../../alert/_models'
import { Switch } from "@material-ui/core";
import { alpha, styled } from '@mui/material/styles';
import theme from 'react'

export const ChatbotList = () => {
  const { dispatch: alertDispatch } = React.useContext(AlertProviderContext)
  const { bots, dispatch } = React.useContext(ChatbotManagerContext)

  const [filters, setFilters] = React.useState<Partial<ChatbotModel & { files: string }>>({
    bot_name: '',
    system_prompt: '',
    language: null,
    files: '',
  })
  const [selectedFiles, setSelectedFiles] = React.useState<{ [key: string]: boolean }>({})

  const filterFunc = ({ bot, files }: { bot: ChatbotModel; files: FileMapType }) => {
    if (filters.bot_name && !bot.bot_name.includes(filters.bot_name)) return false
    if (filters.bot_name && !bot.system_prompt.includes(filters.system_prompt as string))
      return false
    if (filters.language && bot.language && !bot.language.includes(filters.language)) return false
    if (filters.files) {
      let included = false
      for (const file of Object.values(files)) {
        if (filters.files.includes(file.id.toString())) {
          included = true
          break
        }
      }
      return included
    }
    return true
  }

  const [showModalCreate, setShowModalCreate] = React.useState(false)
  const [showModalFile, setShowModalFile] = React.useState(false)

  const initValues: Partial<ChatbotModel> = {
    bot_name: '',
    language: 'auto',
    system_prompt: '',
    use_system_prompt: false,
  }

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#DD5151',
      '&:hover': {
        backgroundColor: alpha('#DD5151', theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#DD5151',
    },
  }));

  return (
    <div>
      <div className='mb-2 mb-lg-5 d-flex flex-end'>
        {/* <div className='me-2'>
          <button className='btn btn-light-primary' onClick={() => setShowModalFile(true)}>
            <KTIcon iconName='file' className='fs-2' />
            Filter with files
          </button>
        </div> */}
        <div className='me-2'>
          <button
            className='btn btn-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='filter' className='fs-2' />
            <span>Filter</span>
          </button>
          <DropdownChatbot setFilter={setFilters} />
        </div>
        <Button onClick={() => setShowModalCreate(true)}>
          <span>Create New</span>
        </Button>
      </div>
      <div className='card'>
        <div className='p-5'>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 gy-7'>
              <thead>
                <tr className='fw-bold fs-6 text-gray-800'>
                  <th style={{ width: '5%' }}></th>
                  <th style={{ width: '20%' }}>Name</th>
                  {/* <th style={{ width: '40%' }}>Instruction</th> */}
                  <th style={{ width: '35%' }}> </th>
                  {/* <th style={{ width: '20%' }}>Connected Files</th> */}
                  <th style={{ width: '20%' }}> </th>
                  {/* <th>Actions</th> */}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {Object.values(bots)
                  .filter(filterFunc)
                  .map(({ bot }) => (
                    <ChatbotRow key={bot.id} chatbot={bot} />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className='modal show' style={{ display: 'block', position: 'initial' }}>
        <Modal show={showModalCreate} centered onHide={() => setShowModalCreate(false)}>
          <Modal.Header closeButton>
            <div>
              <Modal.Title>Create New</Modal.Title>
              {/* <div className={clsx({ 'text-danger': restCreation === 0 })}>
                {restCreation} creation left
              </div> */}
            </div>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={initValues}
              validate={(values) => {
                const errors: any = {}
                if (values.bot_name === '') {
                  errors.bot_name = `Chatbot 's name is required`
                }
                return errors
              }}
              onSubmit={function (
                values: FormikValues,
                formikHelpers: FormikHelpers<FormikValues>
              ): void | Promise<any> {
                formikHelpers.setSubmitting(false)
                setShowModalCreate(false)
                const chatbot: Partial<ChatbotModel> = {
                  bot_name: values.bot_name,
                  language: values.language,
                  system_prompt: values.system_prompt || null,
                  use_system_prompt: values.use_system_prompt,
                }
                createChatbot(chatbot)
                  .then((data) => {
                    // alert success
                    alertDispatch({
                      type: AlertProviderActionType.ADD_MESSAGE,
                      item: {
                        type: AlertMessageType.SUCCESS,
                        message: 'Successfully created chatbot',
                      },
                    })
                    dispatch({
                      type: ChatbotManagerActionType.CHATBOT_ITEM,
                      item: data,
                    })
                  })
                  .catch((err) => {
                    // alert failed
                    alertDispatch({
                      type: AlertProviderActionType.ADD_MESSAGE,
                      item: {
                        type: AlertMessageType.FAILED,
                        message: `${err}`,
                      },
                    })
                  })
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className='px-7 py-5'>
                    <div className='mb-10'>
                      <label className='form-label fw-bold'>Name:</label>
                      <div>
                        <input
                          type='text'
                          name='bot_name'
                          className='form-control form-control-solid'
                          value={values.bot_name}
                          onChange={handleChange}
                        />
                      </div>
                      {touched.bot_name && errors.bot_name && (
                        <div className='fv-plugins-message-container'>
                          <span role='alert' className='text-danger'>
                            {errors.bot_name}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className='mb-0' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <label className='form-label fw-bold'>System prompt:</label>
                      <CustomSwitch
                        name="use_system_prompt"
                        color="primary"
                        value={`${values.use_system_prompt}`}
                        checked={values.use_system_prompt}
                        onChange={(event, checked) => {
                          setFieldValue("use_system_prompt", checked);
                        }}
                        // style = {{ color: values.use_system_prompt ? '#DD5151' : undefined, }}
                      />
                    </div>
                    
                    <div className='mb-10'>
                      <div>
                        <input
                          type='text'
                          name='system_prompt'
                          className='form-control form-control-solid'
                          value={values.system_prompt}
                          onChange={handleChange}
                          disabled={!values.use_system_prompt}
                          style={{ backgroundColor: !values.use_system_prompt ? '#D9D9D9' : '' }}
                        />
                      </div>
                    </div>

                    <div className='mb-10'>
                      <label className='form-label fw-bold'>Language:</label>

                      <div className='d-flex'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                          <input
                            className='form-check-input'
                            type='radio'
                            value='vi'
                            name='language'
                            onChange={handleChange}
                            defaultChecked={initValues.language === 'vi'}
                          />
                          <span className='form-check-label text-gray-800'>Vietnamese</span>
                        </label>

                        <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                          <input
                            className='form-check-input'
                            type='radio'
                            value='en'
                            name='language'
                            onChange={handleChange}
                            defaultChecked={initValues.language === 'en'}
                          />
                          <span className='form-check-label text-gray-800'>English</span>
                        </label>

                        <label className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input'
                            type='radio'
                            value='auto'
                            name='language'
                            onChange={handleChange}
                            defaultChecked={initValues.language === 'auto'}
                          />
                          <span className='form-check-label text-gray-800'>Auto</span>
                        </label>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end'>
                      <button
                        type='submit'
                        className='btn btn-sm btn-primary'
                        data-kt-menu-dismiss='true'
                        disabled={isSubmitting}
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  )
}

import clsx from 'clsx'
import React, { ChangeEvent } from 'react'
import { useDrop, DndProvider } from 'react-dnd'
import { NativeTypes, HTML5Backend } from 'react-dnd-html5-backend'
import { MdCloudUpload } from 'react-icons/md'
import { Button } from 'react-bootstrap'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FileItem } from './FileItem'
import { WithChildren } from '../../../../_metronic/helpers'
import {
  uploaderReducer,
  UploaderState,
  initUploaderState,
  UploaderActionType,
  SelectedFile,
} from './util'

type Props = {
  onUpload: (uploadedFiles: { [key: string]: SelectedFile }) => void
}

const Uploader: React.FC<Props & WithChildren> = ({ onUpload }) => {
  const inputField = React.useRef<HTMLInputElement>(null)
  const [{ selectedFiles }, dispatch] = React.useReducer(uploaderReducer, initUploaderState)
  const numSelectedFiles = Object.keys(selectedFiles).length
  const hasSelectedFiles = numSelectedFiles > 0

  const [{ isOver }, dropRef] = useDrop({
    accept: NativeTypes.FILE,
    drop: (item: DataTransfer) =>
      dispatch({ type: UploaderActionType.ADD_FILES, files: item.files }),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  })

  const handleUpload = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files !== null) {
      dispatch({
        type: UploaderActionType.ADD_FILES,
        files: event.target.files,
      })
    }
  }

  const handleRemoveFile = (randomId: string) => {
    dispatch({
      type: UploaderActionType.REMOVE_FILE,
      fileRandomId: randomId,
    })
  }

  const handleClickCancel = (e: any) => {
    dispatch({ type: UploaderActionType.RESET_SELECTED_FILES })
    if (inputField.current) inputField.current.value = ''
  }

  return (
    <div ref={dropRef} className='position-relative h-500px z-index-0 uploader-box'>
      {/* upload zone (dashed border) */}
      <div
        className={clsx(
          'border-dashed',
          'border-primary',
          'bg-light-primary',
          'position-absolute bg-transparent',
          'uploader-zone',
          'w-100 h-100 m-0',
          isOver && 'is-over'
        )}
      ></div>
      {/* uploader area (with cloud upload button, rendered when having no selected files) */}
      <div
        className={clsx(
          'z-index-2',
          'position-absolute',
          'h-100',
          'w-100',
          'flex-column',
          'align-items-center',
          'justify-content-center',
          'cursor-pointer'
        )}
        style={{
          display: hasSelectedFiles ? 'none' : 'flex',
        }}
        onClick={() => {
          if (inputField.current) inputField.current.click()
        }}
      >
        <input
          type='file'
          multiple
          ref={inputField}
          hidden
          onChange={handleUpload}
          accept='.pdf,.docx,.doc,.txt,.csv'
        />
        <MdCloudUpload color='#219cd7' size={60} />
        <h2 className='mt-2 text-primary'>DRAG, DROP OR CLICK TO UPLOAD!</h2>
        <span className='text-primary'>PDF, DOCX, DOC, TXT, CSV FORMAT SUPPORTED </span>
      </div>
      {/* uploaded file viewer (when having selected files) */}
      <div
        className={clsx(
          'z-index-3 position-absolute w-100 h-100',
          'flex-column justify-content-between align-items-center',
          'uploader-file-viewer'
        )}
        style={{
          display: hasSelectedFiles ? 'flex' : 'none',
        }}
      >
        {/* option bar */}
        <div
          className={clsx(
            'w-100 d-flex flex-row justify-content-between align-items-center',
            'option-bar'
          )}
        >
          <Button variant='danger' onClick={handleClickCancel}>
            <FontAwesomeIcon icon={icon({ name: 'xmark' })} />
            <span className='ms-2'>Cancel</span>
          </Button>
          <div className='fw-bold flex-grow-1 text-center' style={{ fontSize: '1.2em' }}>
            {numSelectedFiles} files selected
          </div>
          <Button
            className='hoverable-element'
            onClick={() => {
              if (inputField.current) inputField.current.click()
            }}
          >
            <FontAwesomeIcon icon={icon({ name: 'plus' })} />
            <span className='ms-2'>Add more</span>
          </Button>
        </div>
        {/* file viewer main area */}
        <div className='gx-5 p-5 w-100 flex-grow-1 overflow-y-scroll' style={{ minHeight: 0 }}>
          <div className='d-flex row'>
            {Object.values(selectedFiles).map((item) => {
              return (
                <FileItem
                  item={item}
                  onItemRemove={() => handleRemoveFile(item.randomId)}
                  key={item.randomId}
                  uploaderDispatch={dispatch}
                />
              )
            })}
          </div>
        </div>
        {/* upload file submission */}
        <div
          className={clsx(
            'w-100 d-flex flex-row justify-content-end align-items-center',
            'uploader-submission'
          )}
        >
          <Button variant='success' onClick={() => onUpload(selectedFiles)}>
            <FontAwesomeIcon icon={icon({ name: 'upload' })} />
            <span>&nbsp;Upload</span>
          </Button>
        </div>
      </div>
      {/* upload backdrop */}
      <div
        className={clsx(
          'position-absolute',
          'h-100 w-100 flex-column justify-content-center align-items-center',
          'uploader-backdrop',
          isOver && 'is-over'
        )}
        style={{
          display: isOver ? 'flex' : 'none',
        }}
      >
        <svg
          fill='currentColor'
          width='36'
          height='36'
          viewBox='0 0 24 24'
          xmlns='http://www.w3.org/2000/svg'
          fillRule='evenodd'
          clipRule='evenodd'
        >
          <path d='M24 12c0 6.623-5.377 12-12 12s-12-5.377-12-12 5.377-12 12-12 12 5.377 12 12zm-1 0c0 6.071-4.929 11-11 11s-11-4.929-11-11 4.929-11 11-11 11 4.929 11 11zm-11.5-4.828l-3.763 4.608-.737-.679 5-6.101 5 6.112-.753.666-3.747-4.604v11.826h-1v-11.828z' />
        </svg>
        <div className='h-10px'></div>
        <span>Drop your files here</span>
      </div>
    </div>
  )
}

export default (({ onUpload }) => (
  <DndProvider backend={HTML5Backend}>
    <Uploader onUpload={onUpload} />
  </DndProvider>
)) as React.FC<Props & WithChildren>

import React from 'react'
import { FileModel } from '../_models'

type FileManagerAction = {
  type: string
} & {
  [key: string]: any
}

type FileManagerContextProps = {
  files: FileModel[]
  dispatch: React.Dispatch<FileManagerAction>
}

const initFileManagerContext: FileManagerContextProps = { files: [], dispatch: () => {} }
export const FileManagerContext = React.createContext<FileManagerContextProps>(initFileManagerContext)

export const reducer = (files: FileModel[], action: FileManagerAction): FileModel[] => {
  switch (action.type) {
    case 'FILE-RECEIVED':
      return receiveFile(files, action)
    case 'FILE-REMOVED':
      return removeFile(files, action)
    default:
      return files
  }
}

const receiveFile = (files: FileModel[], action: FileManagerAction): FileModel[] => {
  let isUpdate: boolean = false
  let updatedFiles: FileModel[] = []
  files.forEach((file: FileModel, idx: number) => {
    if (file.random_id === action.item.random_id) {
      if (action.item.partial === true) {
        updatedFiles.push({ ...file, ...action.item })
      } else {
        updatedFiles.push(action.item)
      }
      isUpdate = true
    } else {
      updatedFiles.push(file)
    }
  })
  if (isUpdate === false) {
    updatedFiles = [action.item, ...updatedFiles]
  }
  return updatedFiles
}

const removeFile = (files: FileModel[], action: FileManagerAction): FileModel[] => {
  let updatedFiles: FileModel[] = []
  files.forEach((file: FileModel, idx: number) => {
    if (file.random_id !== action.item.random_id) {
      updatedFiles.push(file)
    }
  })
  return updatedFiles
}

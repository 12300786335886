import React from 'react'
import socketIOClient, { Socket } from 'socket.io-client'
import { Button } from 'react-bootstrap'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'react-bootstrap'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { KTIcon, WithChildren } from '../../../_metronic/helpers'
import { reducer, FileManagerContext } from './util'
import { FileModel, FileStatus } from '../_models'
import { DocTable } from './doctable'
import { getAuth } from '../../modules/auth'
import { urlJoin } from '../util'
import { Uploader } from './uploader'
import { DropdownFiles } from '../../../_metronic/partials/content/dropdown/DropdownFile'
import { SelectedFile } from './uploader/util'
import { AlertProviderContext } from '../alert/context'
import { AlertProviderActionType } from '../alert/reducer'
import { AlertMessageType } from '../alert/_models'

const API_URL = process.env.REACT_APP_API_URL as string
const UPLOAD_FILE_ENDPOINT = urlJoin(API_URL, 'upload_file')

export const FileManager: React.FC<WithChildren> = () => {
  const { dispatch: alertDispatch } = React.useContext(AlertProviderContext)
  const [open, setOpen] = React.useState<boolean>(false)
  const [files, dispatch] = React.useReducer(reducer, [])
  const socketRef = React.useRef<Socket | null>(null)

  React.useEffect(() => {
    socketRef.current = socketIOClient(API_URL, { transports: ['websocket'], auth: getAuth() })
    socketRef.current.on('connect', () => {
      console.log('Setup socket connection to backend')
      socketRef.current!.emit('file-status')
    })
    socketRef.current.on('file-item', (item: FileModel) => {
      handleOnFileReceive(item)
    })
  }, [])

  const handleOnFileReceive = (item: Partial<FileModel>): void => {
    dispatch({
      type: 'FILE-RECEIVED',
      item,
    })
    if (item.hasOwnProperty('error')) {
      alertDispatch({
        type: AlertProviderActionType.ADD_MESSAGE,
        item: {
          type: AlertMessageType.FAILED,
          message: item.error,
        },
      })
    }
  }

  const handleOnUpload = (uploadedFiles: { [key: string]: SelectedFile }): void => {
    setOpen(false)
    for (const item of Object.values(uploadedFiles)) {
      const uploadedAt = moment.utc().format('YYYY-MM-DD HH:mm:ss.SSSSSSZ')

      // prepare data
      const payload = new FormData()
      payload.append('file', item.file)
      payload.append('random_id', item.randomId)
      payload.append('emb_type', item.embType)
      payload.append('title', item.title)
      payload.append('file_type', item.file_type)
      payload.append('description', item.description)

      // init XHR request
      const xhr = new XMLHttpRequest()
      xhr.upload.onprogress = function (event) {
        if (event.lengthComputable) {
          const bytesSent = event.loaded
          const totalBytes = event.total
          handleOnFileReceive({
            random_id: item.randomId,
            file_name: item.file.name,
            title: item.title,
            file_type: item.file_type,
            description: item.description,
            status: FileStatus.UPLOADING,
            updated_at: uploadedAt,
            progress: bytesSent,
            total: totalBytes,
          })
        }
      }
      xhr.onload = function () {
        const statusCode = xhr.status
        const responseData = xhr.responseText
        if (statusCode === 200) {
          socketRef.current!.emit('file-status', { random_id: item.randomId })
        } else {
          let metaData = {}
          try {
            metaData = JSON.parse(responseData)
          } catch (e) {
            metaData = { error: responseData }
            console.log(responseData)
          }
          handleOnFileReceive({
            random_id: item.randomId,
            file_name: item.file.name,
            title: item.title,
            file_type: item.file_type,
            description: item.description,
            status: FileStatus.FAILED,
            updated_at: uploadedAt,
            ...metaData,
          })
        }
      }
      xhr.onerror = function () {
        console.log('Network error during XHR request')
        handleOnFileReceive({
          random_id: item.randomId,
          file_name: item.file.name,
          status: FileStatus.FAILED,
          title: item.title,
          file_type: item.file_type,
          description: item.description,
          updated_at: uploadedAt,
        })
      }
      xhr.open('POST', UPLOAD_FILE_ENDPOINT, true)
      const auth = getAuth()
      if (auth) {
        xhr.setRequestHeader('Authorization', 'Token ' + auth.token)
      }
      xhr.send(payload)
    }
  }

  return (
    <FileManagerContext.Provider value={{ files, dispatch }}>
      <div
        className='
          d-flex
          flex-column
          w-100
          h-100
          position-relative
        '
      >
        <div className='w-100 mb-3 d-flex align-items-center justify-content-end'>
          <button
            className='btn btn-light-primary me-2'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='filter' className='fs-2' />
            <span>Filter</span>
          </button>
          <DropdownFiles />
          <Button variant='primary' onClick={() => setOpen(true)}>
            <FontAwesomeIcon icon={icon({ name: 'plus' })} />
            <span>&nbsp;Upload file</span>
          </Button>
        </div>
        <Modal show={open} onHide={() => setOpen(false)} centered={true} size='lg'>
          <Modal.Header>
            <Modal.Title>Upload file</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Uploader onUpload={handleOnUpload} />
          </Modal.Body>
        </Modal>
        <DocTable />
      </div>
    </FileManagerContext.Provider>
  )
}

import { lazy, FC, Suspense, useEffect } from 'react'
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { ChatbotManagerWrapper } from '../custom/chatbots'
import { FileManagerWrapper } from '../custom/files/wrapper'
import { useLayout } from '../../_metronic/layout/core'
import { ApiKeyManagement } from '../modules/api-key/ApiKeyManagement'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const DashboardPage = lazy(() => import('../modules/dashboard/Dashboard'))

  const layout = useLayout()

  const { pathname } = useLocation()

  useEffect(() => {
    const appConfig = layout.config
    const con = { ...appConfig }
    if (
      con.app &&
      con.app.sidebar &&
      con.app.sidebar.default &&
      con.app.sidebar.default.minimize &&
      con.app.sidebar.default.minimize.desktop
    ) {
      if (pathname.includes('/chatbot/')) {
        if (!con.app.sidebar.default.minimize.desktop.default) {
          con.app.sidebar.default.minimize.desktop.default = true
          layout.setLayout({ ...con })
          // it will trigger a re-render layout, so the sidebar will be minimized. Moreover, splash screen will be shown.
        }
      } else {
        if (con.app.sidebar.default.minimize.desktop.default) {
          con.app.sidebar.default.minimize.desktop.default = false
          layout.setLayout({ ...con })
        }
      }
    }
  }, [pathname])

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Custom routes */}
        <Route path='chatbot/*' element={<ChatbotManagerWrapper />} />
        <Route path='files' element={<FileManagerWrapper />} />
        <Route path='api-keys' element={<ApiKeyManagement />} />
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route
          path='dashboard'
          element={
            <SuspensedView>
              <DashboardPage />
            </SuspensedView>
          }
        />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        {/* <Route
          path='chatbot/:chatbot_id/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        /> */}

        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }

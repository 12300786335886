import React from 'react'
import { HighlightArea, HighlightPlugin } from '@react-pdf-viewer/highlight'
import { ConversationModel, MessageModel, Vote, ReferenceModel } from '../../_models'

type ChatbotDetailState = {
  loading: boolean
  conversations: { [key: string]: ConversationModel }
  selectedConversationId: number
}

export const initChatbotDetailState: ChatbotDetailState = {
  loading: true,
  conversations: {},
  selectedConversationId: -1,
}

export enum ChatbotDetailActionType {
  CONVERSATION_ITEM = 'CONVERSATION_ITEM',
  DELETE_CONVERSATION_ITEM = 'DELETE_CONVERSATION_ITEM',
  LIST_CONVERSATION_READY = 'LIST_CONVERSATION_READY',
  SET_SELECTED_CONVERSATION = 'SET_SELECTED_CONVERSATION',
  SET_LOADING_OFF = 'SET_LOADING_OFF',
  SET_LOADING_ON = 'SET_LOADING_ON',
  UPDATE_CONVERSATION_LLM_TYPE = 'UPDATE_CONVERSATION_LLM_TYPE',
}

type ChatbotDetailAction = {
  type: ChatbotDetailActionType
} & { [key: string]: any }

export const chatbotDetailReducer = (
  state: ChatbotDetailState,
  action: ChatbotDetailAction
): ChatbotDetailState => {
  switch (action.type) {
    case ChatbotDetailActionType.SET_LOADING_OFF:
      return { ...state, loading: false }
    case ChatbotDetailActionType.LIST_CONVERSATION_READY:
      return {
        ...state,
        conversations: Object.fromEntries(
          action.conversations.map((conv: ConversationModel) => [conv.id, conv])
        ),
        loading: false,
      }
    case ChatbotDetailActionType.CONVERSATION_ITEM:
      return { ...state, conversations: { ...state.conversations, [action.item.id]: action.item } }
    case ChatbotDetailActionType.DELETE_CONVERSATION_ITEM:
      return deleteConversation(state, action)
    case ChatbotDetailActionType.SET_SELECTED_CONVERSATION:
      return { ...state, selectedConversationId: action.selectedConversationId }
    case ChatbotDetailActionType.SET_LOADING_ON:
      return { ...state, loading: true }
    case ChatbotDetailActionType.UPDATE_CONVERSATION_LLM_TYPE:
      return updateConversationLLMType(state, action)
    default:
      return state
  }
}

const deleteConversation = (
  state: ChatbotDetailState,
  action: ChatbotDetailAction
): ChatbotDetailState => {
  const updatedConversations = { ...state.conversations }
  delete updatedConversations[action.conversationId]
  return { ...state, conversations: updatedConversations }
}

const updateConversationLLMType = (
  state: ChatbotDetailState,
  action: ChatbotDetailAction
): ChatbotDetailState => {
  const { conversationId, llmType } = action.payload
  return {
    ...state,
    conversations: {
      ...state.conversations,
      [conversationId]: {
        ...state.conversations[conversationId],
        llm_type: llmType,
      },
    },
  }
}

type ChatbotDetailContextProps = {
  loading: boolean
  conversations: { [key: string]: ConversationModel }
  dispatch: React.Dispatch<ChatbotDetailAction>
}

const initChatbotDetailContext: ChatbotDetailContextProps = {
  loading: true,
  conversations: {},
  dispatch: () => {},
}

export const ChatbotDetailContext =
  React.createContext<ChatbotDetailContextProps>(initChatbotDetailContext)

type ConversationDetailState = {
  loading: boolean
  messages: { [key: string]: MessageModel }
  chatStatus: ChatStatus
  inputMessage: string
  runningMessage: string
  references: ReferenceModel[]
  selectedReference: number
  showReferences: boolean
  selectedFileId: string | number
  triggerHighlight: boolean
}

export enum ChatStatus {
  STATIC = 'STATIC',
  MESSAGE_RUNNING = 'MESSAGE_RUNNING',
  BOT_TYPING = 'BOT_TYPING',
}

export const initConversationDetailState: ConversationDetailState = {
  loading: true,
  messages: {},
  chatStatus: ChatStatus.STATIC,
  inputMessage: '',
  runningMessage: '',
  references: [],
  selectedReference: -1,
  showReferences: false,
  selectedFileId: -1,
  triggerHighlight: false,
}

export enum ConversationDetailActionType {
  SET_LOADING_OFF = 'SET_LOADING_OFF',
  SET_LOADING_ON = 'SET_LOADING_ON',
  LIST_MESSAGE_READY = 'LIST_MESSAGE_READY',
  MESSAGE_ITEM = 'MESSAGE_ITEM',
  SAVE_BOT_MESSAGE = 'SAVE_BOT_MESSAGE',
  SAVE_USER_MESSAGE = 'SAVE_USER_MESSAGE',
  SET_CHAT_STATUS = 'SET_CHAT_STATUS',
  SET_INPUT_MESSAGE = 'SET_INPUT_MESSAGE',
  CHUNK_RECEIVED = 'CHUNK_RECEIVED',
  ANSWER_DONE = 'ANSWER_DONE',
  RESET_CONVERSATION_STATE = 'RESET_CONVERSATION_STATE',
  SET_REFERENCES = 'SET_REFERENCES',
  SHOW_REFERENCES = 'SHOW_REFERENCES',
  SET_SELECTED_REFERENCE = 'SET_SELECTED_REFERENCE',
  UPDATE_MESSAGE_REACTION = 'UPDATE_MESSAGE_REACTION',
  UPDATE_REFERENCE_REACTION = 'UPDATE_REFERENCE_REACTION',
  SET_SELECTED_FILE_ID = 'SET_SELECTED_FILE_ID',
  TRIGGER_HIGHLIGHT_ANOTHER_FILE = 'TRIGGER_HIGHLIGHT_ANOTHER_FILE',
}

type ConversationDetailAction = {
  type: ConversationDetailActionType
} & { [key: string]: any }

export const conversationDetailReducer = (
  state: ConversationDetailState,
  action: ConversationDetailAction
): ConversationDetailState => {
  switch (action.type) {
    case ConversationDetailActionType.SET_LOADING_OFF:
      return { ...state, loading: false }
    case ConversationDetailActionType.LIST_MESSAGE_READY:
      return {
        ...state,
        messages: Object.fromEntries(action.messages.map((msg: MessageModel) => [msg.id, msg])),
        loading: false,
      }
    case ConversationDetailActionType.MESSAGE_ITEM:
      return {
        ...state,
        messages: { ...state.messages, [action.item.id]: action.item },
      }
    case ConversationDetailActionType.SET_LOADING_ON:
      return { ...state, loading: true }
    case ConversationDetailActionType.SAVE_BOT_MESSAGE:
      return {
        ...state,
        messages: {
          ...state.messages,
          [action.item.id]: {
            ...action.item,
            references: action.item.references.map((ref: any) => ({ ...ref, fileId: ref.file.id })),
          },
        },
        runningMessage: '',
        chatStatus: ChatStatus.STATIC,
      }
    case ConversationDetailActionType.SAVE_USER_MESSAGE:
      return {
        ...state,
        messages: { ...state.messages, [action.item.id]: action.item },
        chatStatus: ChatStatus.BOT_TYPING,
        inputMessage: '',
        showReferences: false,
        selectedReference: -1,
        references: [],
      }
    case ConversationDetailActionType.SET_CHAT_STATUS:
      return { ...state, chatStatus: action.chatStatus }
    case ConversationDetailActionType.SET_INPUT_MESSAGE:
      return { ...state, inputMessage: action.inputMessage }
    case ConversationDetailActionType.CHUNK_RECEIVED:
      const nextRunningMessage = state.runningMessage + action.chunk
      return { ...state, runningMessage: nextRunningMessage }
    case ConversationDetailActionType.RESET_CONVERSATION_STATE:
      return { ...initConversationDetailState, selectedFileId: state.selectedFileId }
    case ConversationDetailActionType.SET_REFERENCES:
      return {
        ...state,
        references: action.references.map((item: any) => ({
          fileId: item.file_id,
          highlights: item.highlights,
          can_highlight: item.can_highlight,
          text: item.text,
        })),
      }
    case ConversationDetailActionType.SHOW_REFERENCES:
      return { ...state, showReferences: true }
    case ConversationDetailActionType.SET_SELECTED_REFERENCE:
      return { ...state, selectedReference: action.idx }
    case ConversationDetailActionType.UPDATE_MESSAGE_REACTION:
      return updateMessageVote(state, action)
    case ConversationDetailActionType.UPDATE_REFERENCE_REACTION:
      return updateReferenceVote(state, action)
    case ConversationDetailActionType.SET_SELECTED_FILE_ID:
      return { ...state, selectedFileId: action.selectedFileId, triggerHighlight: false }
    case ConversationDetailActionType.TRIGGER_HIGHLIGHT_ANOTHER_FILE:
      return { ...state, selectedFileId: action.selectedFileId, triggerHighlight: true }
    default:
      return state
  }
}

const updateMessageVote = (
  state: ConversationDetailState,
  action: ConversationDetailAction
): ConversationDetailState => {
  const { messageId, reaction } = action.payload
  const nextMessage = {
    ...state.messages[messageId],
    vote: {
      reaction,
      answer: messageId,
      question: null,
      reference: null,
    },
  }
  return { ...state, messages: { ...state.messages, [messageId]: nextMessage } }
}

const updateReferenceVote = (
  state: ConversationDetailState,
  action: ConversationDetailAction
): ConversationDetailState => {
  const { messageId, referenceId, reaction } = action.payload
  const references = state.messages[messageId].references || []
  const nextReferences = []
  for (const reference of references) {
    if (reference.id === referenceId) {
      nextReferences.push({
        ...reference,
        vote: {
          reaction,
          answer: null,
          question: null,
          reference: referenceId,
        },
      })
    } else {
      nextReferences.push(reference)
    }
  }
  return {
    ...state,
    messages: {
      ...state.messages,
      [messageId]: {
        ...state.messages[messageId],
        references: nextReferences,
      },
    },
  }
}

type ConversationDetailContextProps = {
  dispatch: React.Dispatch<ConversationDetailAction>
  state: ConversationDetailState
  highlightPluginInstance?: HighlightPlugin
}

const initConversationDetailContextProps: ConversationDetailContextProps = {
  dispatch: () => {},
  state: initConversationDetailState,
}

export const ConversationDetailContext = React.createContext<ConversationDetailContextProps>(
  initConversationDetailContextProps
)

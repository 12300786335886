import axios from 'axios'
import React from 'react'
import { Outlet } from 'react-router-dom'
import socketIOClient, { Socket } from 'socket.io-client'
import TopBarProgress from 'react-topbar-progress-indicator'
import {
  chatbotManagerReducer,
  initChatbotManagerState,
  getChatbotManagerContext,
  ChatbotManagerActionType,
  ChatbotManagerContext,
} from './util'
import { API_URL } from '../../const'
import { getAuth } from '../../modules/auth'
import { ChatbotModel, FileModel } from '../_models'
import { AlertProviderContext } from '../alert/context'
import { AlertProviderActionType } from '../alert/reducer'
import { AlertMessageType } from '../alert/_models'

export const ChatbotManager: React.FC = () => {
  const { dispatch: alertDispatch } = React.useContext(AlertProviderContext)
  const [state, dispatch] = React.useReducer(chatbotManagerReducer, initChatbotManagerState)
  const socketRef = React.useRef<Socket | null>(null)

  const getChatbots = async () => {
    dispatch({
      type: ChatbotManagerActionType.SET_LOADING_ON,
    })
    getChatbotManagerContext()
      .then((data) => {
        dispatch({ type: ChatbotManagerActionType.SET_CHATBOTS, data })
      })
      .catch((err) => {
        alertDispatch({
          type: AlertProviderActionType.ADD_MESSAGE,
          item: {
            type: AlertMessageType.FAILED,
            message: `${err}`,
          },
        })
      })
  }

  React.useEffect(() => {
    const requestForContext = async () => {
      try {
        const data = await getChatbotManagerContext()
        dispatch({ type: ChatbotManagerActionType.SET_CHATBOTS, data })
      } catch (e) {
        console.log(e)
      }
    }
    requestForContext()

    socketRef.current = socketIOClient(API_URL as string, {
      transports: ['websocket'],
      auth: getAuth(),
    })
    socketRef.current.on('connect', () => {
      console.log('Setup socket connection to backend')
    })
    socketRef.current.on(
      'bot-item',
      (bot: {
        bot: ChatbotModel
        files: {
          [key: string]: FileModel
        }
      }) => {
        dispatch({
          type: ChatbotManagerActionType.CHATBOT_ITEM,
          item: bot,
        })
      }
    )
    return () => {
      socketRef.current?.disconnect()
    }
  }, [])

  return (
    <ChatbotManagerContext.Provider
      value={{
        loading: state.loading,
        bots: state.bots,
        dispatch,
        socketRef,
        getChatbots,
      }}
    >
      {state.loading ? <TopBarProgress /> : <Outlet />}
    </ChatbotManagerContext.Provider>
  )
}

import clsx from 'clsx'
import moment from 'moment'
import React, { useEffect } from 'react'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ExtendedHighlightArea,
  MessageModel,
  MessageRole,
  Reaction,
  ReferenceModel,
} from '../../_models'
import { ConversationDetailContext, ChatStatus, ConversationDetailActionType } from './util'
import {
  ModalDebugMessage,
  DropdownReactMessage,
} from '../../../../_metronic/partials/content/dropdown/DropdownReactMessage'
import { useIntl } from 'react-intl'
import { updateVote, cancelVote } from '../util'
import { right } from '@popperjs/core'

type SinglePastReferenceProps = {
  reference: ReferenceModel
  renderReaction: (...args: any) => React.ReactNode
  messageId: string | number
}
const SinglePastReference: React.FC<SinglePastReferenceProps> = ({
  reference,
  renderReaction,
  messageId,
}) => {
  const { dispatch } = React.useContext(ConversationDetailContext)
  const [showDropdown, setShowDropdown] = React.useState<boolean>(false)
  const iconRef = React.useRef<HTMLElement>(null)
  useEffect(() => {
    console.log('reference', reference)
  }, [])
  const handleVoteReference = (item: ReferenceModel, r: Reaction) => {
    setShowDropdown(false)
    const itemReaction = item?.vote ? item.vote.reaction : Reaction.NONE
    if (itemReaction === r) {
      updateVote(item.id, Reaction.NONE, 'reference')
      dispatch({
        type: ConversationDetailActionType.UPDATE_REFERENCE_REACTION,
        payload: {
          messageId,
          referenceId: item.id,
          reaction: Reaction.NONE,
        },
      })
    } else {
      updateVote(item.id, r, 'reference')
      dispatch({
        type: ConversationDetailActionType.UPDATE_REFERENCE_REACTION,
        payload: {
          messageId,
          referenceId: item.id,
          reaction: r,
        },
      })
    }
  }

  const getPosition = () => {
    if (iconRef.current) {
      const rect = iconRef.current.getBoundingClientRect()
      const left = `${rect.x + rect.width + 10}px`
      const top = `${rect.y}px`
      return { left, top }
    }
    return {}
  }

  const getReaction = (item: ReferenceModel) => {
    if (item.vote) {
      return item.vote.reaction
    }
    return Reaction.NONE
  }

  return (
    <div className='my-3 d-flex flex-row position-relative' key={reference.id}>
      <div className={clsx('hoverable-element px-5 py-3 rounded position-relative flex-grow-1')}>
        <div className='w-100 d-flex justify-content-end'>
          <span className='badge badge-success mb-3'>{reference.file?.file_name}</span>
        </div>

        <span className='d-block' style={{ textAlign: 'justify' }}>
          {reference.text}
        </span>
        {renderReaction(getReaction(reference), '-8px', '25px')}
      </div>
      <div className='d-flex align-items-center justify-content-end position-relative'>
        <button className='btn btn-icon btn-sm fs-6' onClick={() => setShowDropdown(!showDropdown)}>
          <i ref={iconRef} className='bi bi-three-dots-vertical fs-2'></i>
        </button>
        <div className='position-fixed' style={{ ...getPosition(), zIndex: 100 }}>
          <DropdownReactMessage
            show={showDropdown}
            item={reference}
            voting={handleVoteReference as (arg: ReferenceModel | MessageModel) => void}
          />
        </div>
      </div>
    </div>
  )
}

type PastReferencesProps = {
  messageId: number | string
  references: ReferenceModel[]
  renderReaction: (reaction: Reaction) => React.ReactNode
}

const PastReferencesAndDebug: React.FC<PastReferencesProps> = ({
  messageId,
  references,
  renderReaction,
}) => {
  const intl = useIntl()
  const [show, setShow] = React.useState<boolean>(false)
  const [showDebug, setShowDebug] = React.useState<boolean>(false)
  useEffect(() => {
    console.log('references', references)
  }, [])
  const renderReferences = () => {
    if (show === false) return null
    return (
      <div className='d-flex flex-column justify-content-start, align-items-stretch px-5'>
        <div className='fw-bold'>{intl.formatMessage({ id: 'CHAT_MESSAGE.REFERENCES' })}</div>
        <div className='px-4'>
          {references.map((ref: ReferenceModel) => {
            return (
              <SinglePastReference
                key={ref.id}
                renderReaction={renderReaction}
                reference={ref}
                messageId={messageId}
              />
            )
          })}
        </div>
      </div>
    )
  }

  const renderDebugModal = () => {
    if (showDebug === false) return null
    return (
      <ModalDebugMessage
        id={messageId as string}
        show={showDebug}
        onHidden={() => setShowDebug(false)}
      />
    )
  }

  return (
    <>
      <div className='d-flex flex-center w-100px mx-auto'>
        <FontAwesomeIcon
          icon={icon({ name: 'ellipsis' })}
          className={clsx('reference-insert')}
          onClick={() => setShow(!show)}
        />
        {process.env.REACT_APP_DEV === 'true'}
        <FontAwesomeIcon
          icon={icon({ name: 'terminal' })}
          className={clsx('reference-insert')}
          onClick={() => setShowDebug(!showDebug)}
        />
      </div>
      {renderReferences()}
      {renderDebugModal()}
    </>
  )
}

type MessageItemProps = {
  item: MessageModel
}

const MessageItem: React.FC<MessageItemProps> = ({ item }) => {
  const { dispatch } = React.useContext(ConversationDetailContext)
  const state = item.role === MessageRole.ASSISTANT ? 'info' : 'primary'
  const bg_mgs_color = item.role === MessageRole.ASSISTANT ? '#fff2f2' : '#f3f3f3'
  const contentClass = `d-flex justify-content-${
    item.role === MessageRole.ASSISTANT ? 'start' : 'end'
  } mb-5 mt-5`
  const [showDropdown, setShowDropdown] = React.useState<boolean>(false)
  const [showDebug, setShowDebug] = React.useState<boolean>(false)

  const getReaction = () => {
    if (item.vote) {
      return item.vote.reaction
    }
    return Reaction.NONE
  }

  const renderReaction = (reaction: Reaction, bottom: string = '-30px', left: string = '30px') => {
    if (item.role === MessageRole.USER) return null
    const Positive_reactionClass = clsx(
      'bi fs-6 position-absolute',
      { 'bi-hand-thumbs-up': reaction === Reaction.NONE },
      { 'bi-hand-thumbs-up-fill ': reaction === Reaction.POSITIVE },
      { 'bi-hand-thumbs-up': reaction === Reaction.NEGATIVE }
    )
    const Negative_reactionClass = clsx(
      'bi fs-6 position-absolute',
      { 'bi-hand-thumbs-down': reaction === Reaction.NONE },
      { 'bi-hand-thumbs-down': reaction === Reaction.POSITIVE },
      { 'bi-hand-thumbs-down-fill': reaction === Reaction.NEGATIVE }
    )

    return <i className='d-flex align-items-center justify-content-end position-absolute' style={{ bottom, left }}>
      <button
        className={clsx('btn btn-icon btn-circle btn-sm hoverable-element')}
        onClick={(e) => {
          e.stopPropagation()
          handleVoteMessage(item, Reaction.POSITIVE)
        }}
      >
        <i className={Positive_reactionClass}></i>
      </button>
      <div className="d-flex" style={{height: "12px", color:"#C4C4C4"}}>
        <div className="vr"></div>
      </div>
      <button
        className={clsx('btn btn-icon btn-circle btn-sm hoverable-element')}
        onClick={(e) => {
          e.stopPropagation()
          handleVoteMessage(item, Reaction.NEGATIVE)
        }}
      >
        <i className={Negative_reactionClass}></i>
      </button>
    </i>
    }

  const renderReferences = () => {
    if (item.role === MessageRole.USER) return null
    return (
      <PastReferencesAndDebug
        messageId={item.id}
        references={item.references as ReferenceModel[]}
        renderReaction={renderReaction}
      />
    )
  }

  const handleVoteMessage = (item: MessageModel, r: Reaction) => {
    setShowDropdown(false)
    const itemReaction = item?.vote ? item.vote.reaction : Reaction.NONE
    if (itemReaction === r) {
      cancelVote(item.id, Reaction.NONE, 'message')
      dispatch({
        type: ConversationDetailActionType.UPDATE_MESSAGE_REACTION,
        payload: {
          messageId: item.id,
          reaction: Reaction.NONE,
        },
      })
    } else {
      updateVote(item.id, r, 'message')
      dispatch({
        type: ConversationDetailActionType.UPDATE_MESSAGE_REACTION,
        payload: {
          messageId: item.id,
          reaction: r,
        },
      })
    }
  }

  return (
    <>
      <div key={item.id} className={clsx(contentClass)}>
        <div className='d-flex align-items-end position-relative'>
          <div
            className={clsx(
              'd-flex align-items-center',
              item.role === MessageRole.USER ? 'order-2 ms-2' : 'me-2'
            )}
          >
            {item.role === MessageRole.ASSISTANT ? (
              <>
                {/* <div className='symbol symbol-35px symbol-circle'>
                  <div className='symbol-label fw-semibold bg-secondary fs-2 text-gray-600'>A</div>
                </div> */}
                <div>
                  <img
                    src={`${process.env.REACT_APP_FOLDER_IMAGE}/OFIS-Logo-Small.png`}
                    width={30}
                    alt=''
                  />
                </div>
                {/* <div className='ms-3'>
                  <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'>
                    Assistant
                  </a>
                  <span className='text-muted fs-7 mb-1'>
                    {moment(item.updated_at).format('HH:mm')}
                  </span>
                </div> */}
              </>
            ) : (
              <>
                {/* <div className='me-3'>
                  <span className='text-muted fs-7 mb-1'>
                    {moment(item.updated_at).format('HH:mm')}
                  </span>
                  <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'>
                    You
                  </a>
                </div> */}
                {/* <div className='symbol symbol-35px symbol-circle'>
                  <div className='symbol-label fw-semibold bg-secondary fs-2 text-gray-600'>Y</div>
                </div> */}
                <i className="bi bi-person-circle" style={{ fontSize: '30px', color:'#E0E3E9' }}></i>
              </>
            )}
          </div>
          <div
            className={clsx(
              'p-5 d-flex flex-column align-items-end',
              // `bg-light-${state}`,
              // 'text-dark fw-semibold mw-lg-400px',
              'fw-semibold mw-lg-400px',
              `text-${item.role === MessageRole.ASSISTANT ? 'start' : 'start'}`,
              { 'order-1': item.role === MessageRole.USER }
            )} style={{background:bg_mgs_color, borderRadius: '20px', color: '#4c4c4c'}}
          >
            {item.content}
            {item.role === MessageRole.ASSISTANT && renderReaction(getReaction())}
          </div>
          {/* <div>
            {item.role === MessageRole.ASSISTANT && (
              <>
                <div className='w-100 d-flex align-items-center justify-content-end'>
                  <button
                    className='btn btn-icon btn-sm fs-6'
                    onClick={() => setShowDropdown(!showDropdown)}
                  >
                    <i className='bi bi-three-dots-vertical fs-2'></i>
                  </button>
                  <DropdownReactMessage
                    show={showDropdown}
                    item={item}
                    voting={handleVoteMessage as (arg: ReferenceModel | MessageModel) => void}
                  />
                </div>
              </>
            )}
          </div> */}
        </div>
      </div>
      {renderReferences()}
    </>
  )
}

export const MessageList: React.FC = () => {
  const { state } = React.useContext(ConversationDetailContext)

  const renderOnAnswering = () => {
    if (state.chatStatus === ChatStatus.BOT_TYPING) {
      return (
        <div className='d-flex justify-content-start mb-5'>
          <div className='d-flex flex-column align-items-start'>
            <div className='d-flex align-items-center mb-2'>
              {/* <div className='symbol symbol-35px symbol-circle'>
                <div className='symbol-label fw-semibold bg-secondary fs-2 text-gray-600'>A</div>
              </div>
              <div className='ms-3'>
                <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'>
                  Assistant
                </a>
              </div> */}
              <div>
                  <img
                    src={`${process.env.REACT_APP_FOLDER_IMAGE}/OFIS-Logo-Small.png`}
                    width={30}
                    alt=''
                  />
                </div>
            </div>
            <div className='p-5 text-dark fw-bold mw-lg-400px text-start' style={{background:'#fff2f2', borderRadius: '20px'}}>
              <span className='rw-dot'></span>
              <span className='rw-dot'></span>
              <span className='rw-dot'></span>
            </div>
          </div>
        </div>
      )
    } else if (state.chatStatus === ChatStatus.MESSAGE_RUNNING) {
      return (
        <div className='d-flex justify-content-start mb-5'>
          <div className='d-flex flex-column align-items-start'>
            <div className='d-flex align-items-center mb-2'>
              {/* <div className='symbol symbol-35px symbol-circle'>
                <div className='symbol-label fw-semibold bg-secondary fs-2 text-gray-600'>A</div>
              </div>
              <div className='ms-3'>
                <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'>
                  Assistant
                </a>
              </div> */}
              <div>
                <img
                  src={`${process.env.REACT_APP_FOLDER_IMAGE}/OFIS-Logo-Small.png`}
                  width={30}
                  alt=''
                />
              </div>
            </div>
            <div className='p-5 text-dark fw-semibold mw-lg-400px text-start' style={{background:'#fff2f2', borderRadius: '20px'}}>
              {state.runningMessage}
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
  return (
    <>
      {Object.values(state.messages).map((item) => {
        return <MessageItem key={item.id} item={item} />
      })}
      {renderOnAnswering()}
    </>
  )
}

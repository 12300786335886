import React from 'react'
import axios from 'axios'
import { saveAs } from 'file-saver'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert } from 'react-bootstrap'
import { WithChildren } from '../../../../_metronic/helpers'
import { FileManagerContext } from '../util'
import { FileModel } from '../../_models'
import { urlJoin } from '../../util'
import clsx from 'clsx'
import Swal from 'sweetalert2'

const API_URL = process.env.REACT_APP_API_URL as string
const REMOVE_FILE_ENDPOINT = urlJoin(API_URL, 'remove_file')

const getPrefix = (status: string): string => {
  if (status.length === 0) return status
  return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()
}

export const Progress: React.FC<
  WithChildren & Pick<FileModel, 'status' | 'error'> & { progress: number; total: number }
> = ({ status, progress, total, error }) => {
  const prefix = getPrefix(status)
  const percent = Math.floor((progress / total) * 100)

  const getBgColor = (_percent: number) => {
    if (_percent < 25) return ''
    if (_percent < 50) return 'bg-success'
    if (_percent < 75) return 'bg-info'
    if (_percent < 100) return 'bg-warning'
    return 'bg-danger'
  }

  if (['UPLOADING', 'INDEXING'].includes(status)) {
    return (
      <>
        <div className='progress'>
          <div
            className={clsx(
              'progress-bar progress-bar-striped progress-bar-animate',
              getBgColor(percent)
            )}
            style={{ width: `${percent}%` }}
            role='progressbar'
            aria-valuenow={percent}
            aria-valuemin={0}
            aria-valuemax={100}
          ></div>
        </div>
        <div className='mt-2 text-center span-overflow-ellipsis' style={{ width: `${percent}%` }}>
          {`${prefix} ${percent}%`}
        </div>
      </>
    )
  }

  if (['PARSING', 'CONVERTING', 'SUMMARIZING', 'PROCESSING'].includes(status)) {
    return (
      <div className='d-flex flex-row justify-content-center align-items-center'>
        <div className='spinner-border spinner-border-md' role='status'>
          <span className='sr-only'></span>
        </div>
        <span className='span-overflow-ellipsis'>&nbsp;&nbsp;{`${getPrefix(status)}`}</span>
      </div>
    )
  }

  if (status === 'COMPLETED') {
    return (
      <div className='d-flex flex-row justify-content-center align-items-center fw-bold text-success'>
        <FontAwesomeIcon icon={icon({ name: 'circle-check' })} />
        <span className='span-overflow-ellipsis'>&nbsp;{status}</span>
      </div>
    )
  }

  return (
    <div className='d-flex flex-row justify-content-center align-items-center fw-bold text-danger'>
      <FontAwesomeIcon icon={icon({ name: 'triangle-exclamation' })} />
      <span title={error} className='span-overflow-ellipsis'>
        &nbsp;{status}
      </span>
    </div>
  )
}

const TableRowFile: React.FC<{ file: FileModel }> = ({ file }) => {
  const { dispatch } = React.useContext(FileManagerContext)

  const handleDownloadFile = (): void => {
    const endpoint = urlJoin(API_URL, 'media', file.id.toString())
    axios
      .request({
        method: 'GET',
        url: endpoint,
        responseType: 'blob',
      })
      .then(async (resp) => {
        saveAs(new Blob([resp.data]), file.file_name)
      })
      .catch((error) => {
        alert('File does not exists')
        console.log(error)
      })
  }

  const handleRemoveFile = async () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this file!',
      icon: 'warning',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch({
          type: 'FILE-REMOVED',
          item: file,
        })
        axios
          .request({
            method: 'POST',
            url: REMOVE_FILE_ENDPOINT,
            headers: {
              'Content-Type': 'application/json',
            },
            data: JSON.stringify({ file_id: file.id }),
          })
          .then(() => Swal.fire('Deleted!', 'Your file has been deleted.', 'success'))
          .catch((error) => {
            console.log(error)
          })
      }
      Swal.close()
    })
  }

  return (
    <tr>
      <td className='align-middle'>
        <span
          title={file.file_name}
          className='hoverable-element span-overflow-ellipsis d-block ms-4'
          onClick={handleDownloadFile}
        >
          {file.file_name}
        </span>
      </td>
      <td className='align-middle'>
        <span className='span-overflow-ellipsis d-block' title={file.updated_at}>
          {file.updated_at}
        </span>
      </td>
      <td className='align-middle'>
        <Progress
          status={file.status}
          progress={file?.progress || -1}
          total={file?.total || -1}
          error={file?.error || ''}
        />
      </td>
      <td className='align-middle'>
        <a
          href='#'
          className='btn btn-light btn-active-light-primary btn-sm'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
        >
          Actions<i className='ki-duotone ki-down fs-5 m-0'></i>
        </a>
        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
          data-kt-menu='true'
        >
          <div className='menu-item px-3' onClick={handleDownloadFile}>
            <a className='menu-link px-3'>Download</a>
          </div>
          <div className='menu-item px-3' onClick={handleRemoveFile}>
            <a className='menu-link px-3' data-kt-users-table-filter='delete_row'>
              Delete
            </a>
          </div>
        </div>
        {/* <div
          className='d-flex flex-row align-items-center justify-content-center h-100'
          style={{ gap: '1em' }}
        >
          <i className='bi bi-x fs-2 hoverable-element' onClick={handleRemoveFile}></i>
          <i className='bi bi-download fs-2 hoverable-element' onClick={handleDownloadFile}></i>
        </div> */}
      </td>
    </tr>
  )
}

export const DocTable: React.FC<WithChildren> = () => {
  const { files } = React.useContext(FileManagerContext)
  return (
    <div className='card w-100'>
      <div className='p-5'>
        <div className='table-responsive'>
          <table
            className='table table-hover table-row-dashed table-row-gray-300 gy-7'
            style={{ tableLayout: 'fixed' }}
          >
            <thead>
              <tr className='fw-bold fs-6 text-gray-800'>
                <th style={{ width: '30%' }}>Name</th>
                <th style={{ width: '25%' }}>Uploaded at</th>
                <th className='text-center' style={{ width: '35%' }}>
                  Status
                </th>
                <th className='text-center' style={{ width: '10%' }}>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {files.map((file) => {
                return <TableRowFile key={file.random_id} file={file} />
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

import clsx from 'clsx'
import { MessageModel, Reaction, ReferenceModel } from '../../../../app/custom/_models'
import { useEffect, useRef, useState } from 'react'
import { getDebugMessage } from '../../../../app/custom/chatbots/util'
import { Modal } from 'react-bootstrap'
import { prettyPrintJson } from 'pretty-print-json'
import './style.css'

export const DropdownReactMessage = ({
  show,
  item,
  voting,
}: {
  show: boolean
  item: ReferenceModel | MessageModel
  voting: (arg: ReferenceModel | MessageModel, r: Reaction) => void
}) => {
  const reaction = item.vote ? item.vote.reaction : Reaction.NONE
  return (
    <div className={clsx('menu menu-sub menu-sub-dropdown w-75px', { show })}>
      <div className='d-flex justify-content-between'>
        <button
          className={clsx('btn btn-icon btn-circle btn-sm hoverable-element', {
            'btn-secondary': reaction === Reaction.POSITIVE,
          })}
          onClick={(e) => {
            e.stopPropagation()
            voting(item, Reaction.POSITIVE)
          }}
        >
          <i className='bi bi-hand-thumbs-up-fill text-warning'></i>
        </button>
        <button
          className={clsx('btn btn-icon btn-circle btn-sm hoverable-element', {
            'btn-secondary': reaction === Reaction.NEGATIVE,
          })}
          onClick={(e) => {
            e.stopPropagation()
            voting(item, Reaction.NEGATIVE)
          }}
        >
          <i className='bi bi-hand-thumbs-down-fill text-warning'></i>
        </button>
      </div>
    </div>
  )
}

export const ModalDebugMessage = ({
  id,
  show,
  onHidden,
}: {
  id: string
  show: boolean
  onHidden: () => void
}) => {
  const [message, setMessage] = useState<any>()
  const searchResultRef = useRef<HTMLPreElement>(null)

  useEffect(() => {
    const request = async () => {
      try {
        const message = await getDebugMessage(id)
        setMessage(message)
        const search_result = message.search_result
          .replace(/'/g, '"')
          .replace(/False/g, 'false')
          .replace(/True/g, 'true')
        console.log(search_result, 'search result')
        searchResultRef.current!.innerHTML = prettyPrintJson.toHtml(JSON.parse(search_result), {})
      } catch (e) {}
    }
    request()
  }, [])

  return (
    <Modal show={show} onHide={onHidden} centered size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Debug Message</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: '80vh', overflowY: 'scroll' }}>
        <label htmlFor='' className='fw-bold fs-1'>
          Prompt
        </label>
        <div>
          <code className='text-justify'>
            {message?.constructed_prompt ? message.constructed_prompt : ''}
          </code>
        </div>

        {message?.error && (
          <>
            <label htmlFor='' className='text-danger'>
              Error
            </label>
            <input type='text' className='form-control' defaultValue={message.error} />
          </>
        )}

        <label htmlFor='' className='mt-5 fw-bold fs-1'>
          Search Result
        </label>
        <div className='bg-secondary rounded-2 p-5'>
          <pre ref={searchResultRef} className='json-container'></pre>
        </div>
      </Modal.Body>
    </Modal>
  )
}

import axios from 'axios'
import React from 'react'
import { Socket } from 'socket.io-client'
import { API_URL } from '../../const'
import { FileModel, ChatbotModel, ConversationModel, Reaction } from '../_models'
import { urlJoin } from '../util'

const GET_CHATBOT_MANAGER_CONTEXT_URL = `${API_URL}/botMngCtx`
const UPDATE_SELECTED_FILE_URL = `${API_URL}/update_selected_file`
const CRUD_CHATBOT_URL = `${API_URL}/chatbot`
const API_VOTE = `${API_URL}/vote`

// chatbot manager state
export type FileMapType = {
  [key: string]: FileModel
}

export type BotVerboseMapType = {
  [key: string]: {
    bot: ChatbotModel
    files: FileMapType
  }
}

export type ChatbotManagerState = {
  bots: BotVerboseMapType
  loading: boolean
}

export const initChatbotManagerState: ChatbotManagerState = {
  bots: {},
  loading: true,
}

// chatbot manager reducer
export enum ChatbotManagerActionType {
  SET_SELECTED_FILES = 'SET_SELECTED_FILES',
  SET_CHATBOTS = 'SET_CHATBOTS',
  SET_LOADING_OFF = 'SET_LOADING_OFF',
  SET_LOADING_ON = 'SET_LOADING_ON',
  CHATBOT_ITEM = 'CHATBOT_ITEM',
  UPDATE_SELECTED_FILES = 'UPDATE_SELECTED_FILES',
  DELETE_CHATBOT = 'DELETE_CHATBOT',
}

type ChatbotManagerAction = {
  type: ChatbotManagerActionType
} & {
  [key: string]: any
}

export const chatbotManagerReducer = (
  state: ChatbotManagerState,
  action: ChatbotManagerAction
): ChatbotManagerState => {
  switch (action.type) {
    case ChatbotManagerActionType.SET_CHATBOTS:
      return {
        ...state,
        bots: action.data,
        loading: false,
      }
    case ChatbotManagerActionType.SET_SELECTED_FILES:
      return {
        ...state,
        bots: {
          ...state.bots,
          [action.botId]: {
            ...state.bots[action.botId],
            files: Object.fromEntries(
              action.selectedFiles.map((item: FileModel) => [item.id, item])
            ),
          },
        },
      }
    case ChatbotManagerActionType.SET_LOADING_OFF:
      return { ...state, loading: false }
    case ChatbotManagerActionType.SET_LOADING_ON:
      return { ...state, loading: true }
    case ChatbotManagerActionType.CHATBOT_ITEM:
      return {
        ...state,
        bots: {
          ...state.bots,
          [action.item.id]: {
            bot: action.item,
            files: {},
          },
        },
      }
    case ChatbotManagerActionType.UPDATE_SELECTED_FILES:
      return {
        ...state,
        bots: {
          ...state.bots,
          [action.chatbotId]: {
            ...state.bots[action.chatbotId],
            files: action.files,
          },
        },
      }
    case ChatbotManagerActionType.DELETE_CHATBOT:
      return deleteChatbot(state, action)
    default:
      return state
  }
}

const deleteChatbot = (
  state: ChatbotManagerState,
  action: ChatbotManagerAction
): ChatbotManagerState => {
  const { botId } = action
  const {
    bots: { [botId]: deletedChatbot, ...bots },
  } = state
  return { ...state, bots }
}

// chatbot manager context
type ChatbotManagerContextProps = {
  bots: {
    [key: string]: {
      bot: ChatbotModel
      files: {
        [key: string]: FileModel
      }
    }
  }
  loading: boolean
  dispatch: React.Dispatch<ChatbotManagerAction>
  socketRef?: React.MutableRefObject<Socket | null>
  getChatbots: Function
}

const initChatbotManagerContextProps = {
  bots: {},
  loading: true,
  dispatch: () => {},
  getChatbots: () => {},
}

export const ChatbotManagerContext = React.createContext<ChatbotManagerContextProps>(
  initChatbotManagerContextProps
)

// API call for getting chatbot manager context
export const getChatbotManagerContext = async () => {
  const { data: context } = await axios.get<ChatbotManagerState>(GET_CHATBOT_MANAGER_CONTEXT_URL)
  return context
}

// API call for update selected files
export type ChatbotFileRecord = {
  chatbot_id: number
  file_id: number
}
export const updateSelectedFiles = async (records: ChatbotFileRecord[]) => {
  await axios.request<ChatbotFileRecord[]>({
    method: 'POST',
    url: UPDATE_SELECTED_FILE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(records),
  })
}

// API call for create chatbot
export const createChatbot = async (chatbot: Partial<ChatbotModel>) => {
  const { data } = await axios.request<ChatbotModel>({
    method: 'POST',
    url: CRUD_CHATBOT_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(chatbot),
  })
  return data
}

// API call for create conversation
export const createConversation = async (conversation: any) => {
  const endpoint = urlJoin(API_URL as string, 'chatbot', conversation.chatbot, 'conversation')
  const { data } = await axios.request<ConversationModel>({
    method: 'POST',
    url: endpoint,
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(conversation),
  })
  return data
}

export const updateVote = async (
  id: string | number,
  reaction: Reaction,
  type: 'reference' | 'message'
) => {
  const { data } = await axios.request({
    method: 'POST',
    url: API_VOTE,
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({ id, reaction, type }),
  })
  return data
}

export const cancelVote = async (
  id: string | number,
  reaction: Reaction,
  type: 'reference' | 'message'
) => {
  const { data } = await axios.request({
    method: 'DELETE',
    url: API_VOTE,
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({ id, reaction, type }),
  })
  return data
}

export enum LLMType {
  CMC = 'CMC',
  ChatGPT = 'ChatGPT',
  Gemini = 'Gemini',
  Mistral = 'Mistral'
}

export const updateConversationLLMType = async (conversation: any) => {
  const endpoint = urlJoin(API_URL as string, 'conversation', conversation.id)
  const { data } = await axios.request({
    method: 'PATCH',
    url: endpoint,
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({ llm_type: conversation.llm_type }),
  })
  return data
}

export const getDebugMessage = async (messageId: string) => {
  const endpoint = urlJoin(API_URL as string, 'debug_message', messageId)
  const { data } = await axios.get(endpoint)
  return data
}

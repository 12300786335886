import React from 'react'
import { WithChildren } from '../../../_metronic/helpers'
import { AlertProviderActionType, alertReducer } from './reducer'
import { AlertProviderContext } from './context'
import { AlertMessage, AlertMessageType } from './_models'

type AlertItemProps = {
  item: AlertMessage
  itemId: string
}

const AlertItem: React.FC<AlertItemProps> = ({ item, itemId }) => {
  const { dispatch } = React.useContext(AlertProviderContext)

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch({
        type: AlertProviderActionType.REMOVE_ALERT,
        itemId,
      })
    }, 5000)
    return () => clearTimeout(timeoutId)
  }, [])

  const handleCloseAlert = (e: any) => {
    dispatch({
      type: AlertProviderActionType.REMOVE_ALERT,
      itemId,
    })
  }

  const getAlertTypeClassName = () => {
    switch (item.type) {
      case AlertMessageType.SUCCESS:
        return 'success'
      case AlertMessageType.WARNING:
        return 'warning'
      case AlertMessageType.FAILED:
        return 'danger'
      default:
        return 'danger'
    }
  }

  const fgColor = `var(--bs-alert-${getAlertTypeClassName()}-fg)`
  const bgColor = `var(--bs-alert-${getAlertTypeClassName()}-bg)`

  const renderPrefixItem = () => {
    switch (item.type) {
      case AlertMessageType.SUCCESS:
        return <i className='bi bi-check-circle' style={{ fontSize: '1.2rem', color: fgColor }}></i>
      case AlertMessageType.WARNING:
        return <i className='bi bi-exclamation-triangle' style={{ fontSize: '1.2rem', color: fgColor }}></i>
      case AlertMessageType.FAILED:
        return <i className='bi bi-exclamation-circle' style={{ fontSize: '1.2rem', color: fgColor }}></i>
    }
  }

  return (
    <div
      className={`d-flex flex-row justify-content-between align-items-center p-3 rounded border`}
      style={{
        color: fgColor,
        backgroundColor: bgColor,
      }}
    >
      <div>
        {renderPrefixItem()}
        <span className='ms-3'>{item.message}</span>
      </div>
      <div className='ms-2 d-flex flex-column justify-content-start align-self-stretch'>
        <i className='bi bi-x fs-1 hoverable-icon' onClick={handleCloseAlert}></i>
      </div>
    </div>
  )
}

export const AlertProvider: React.FC<WithChildren> = ({ children }) => {
  const [state, dispatch] = React.useReducer(alertReducer, { messages: [] })

  return (
    <AlertProviderContext.Provider value={{ state, dispatch }}>
      <div
        className='position-fixed d-flex flex-column alert-item'
        style={{
          zIndex: 1000,
          bottom: '20px',
          left: '20px',
          fontSize: '1.2rem',
          rowGap: '1rem',
        }}
      >
        {state.messages.map((item) => {
          return <AlertItem item={item} key={item.uid} itemId={item.uid} />
        })}
      </div>
      {children}
    </AlertProviderContext.Provider>
  )
}

import { Formik, FormikHelpers, FormikValues } from 'formik'
import { ChatbotModel } from '../../../../app/custom/_models'

/* eslint-disable jsx-a11y/anchor-is-valid */
export function DropdownChatbot({ setFilter }: { setFilter: Function }) {
  const initValues: Partial<ChatbotModel> = {
    bot_name: '',
    language: null,
    system_prompt: '',
  }

  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
      </div>

      <div className='separator border-gray-200'></div>

      <Formik
        initialValues={initValues}
        validate={(values) => {}}
        onSubmit={function (
          values: FormikValues,
          formikHelpers: FormikHelpers<FormikValues>
        ): void | Promise<any> {
          setFilter((old: any) => {
            return {
              ...old,
              bot_name: values.bot_name,
              language: !values.language || values.language?.length === 2 ? '' : values.language[0],
              system_prompt: values.system_prompt,
            }
          })
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div className='px-7 py-5'>
              <div className='mb-10'>
                <label className='form-label fw-bold'>Name:</label>

                {/* <div>
            <select
              className='form-select form-select-solid'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              defaultValue={'1'}
            >
              <option></option>
              <option value='1'>Approved</option>
              <option value='2'>Pending</option>
              <option value='3'>In Process</option>
              <option value='4'>Rejected</option>
            </select>
          </div> */}
                <div>
                  <input
                    name='bot_name'
                    type='text'
                    className='form-control form-control-solid'
                    value={values.bot_name}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className='mb-10'>
                <label className='form-label fw-bold'>System Prompt:</label>
                <div>
                  <input
                    name='system_prompt'
                    type='text'
                    className='form-control form-control-solid'
                    value={values.system_prompt}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className='mb-10'>
                <label className='form-label fw-bold'>Language:</label>

                <div className='d-flex'>
                  <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                    <input
                      name='language'
                      className='form-check-input'
                      type='checkbox'
                      value='vi'
                      onChange={handleChange}
                    />
                    <span className='form-check-label text-dark'>Vietnamese</span>
                  </label>

                  <label className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      name='language'
                      className='form-check-input'
                      type='checkbox'
                      value='en'
                      onChange={handleChange}
                    />
                    <span className='form-check-label text-dark'>English</span>
                  </label>
                </div>
              </div>

              <div className='d-flex justify-content-end'>
                <button
                  type='reset'
                  className='btn btn-sm btn-light btn-active-light-primary me-2'
                  data-kt-menu-dismiss='true'
                  onClick={() => {
                    resetForm()
                    setFilter((old: any) => {
                      return {
                        ...old,
                        bot_name: '',
                        language: '',
                        system_prompt: '',
                      }
                    })
                  }}
                >
                  Reset
                </button>

                <button
                  type='submit'
                  className='btn btn-sm btn-primary'
                  data-kt-menu-dismiss='true'
                >
                  Apply
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

import { v4 as uuidv4 } from 'uuid'
import { AlertMessage } from './_models'

export type AlertProviderState = {
  messages: AlertMessage[]
}

export const initAlertProviderState: AlertProviderState = {
  messages: [],
}

export enum AlertProviderActionType {
  ADD_MESSAGE = 'ADD_MESSAGE',
  REMOVE_ALERT = 'REMOVE_ALERT',
}

export type AlertProviderAction = {
  type: AlertProviderActionType
} & { [key: string]: any }

export const alertReducer = (
  state: AlertProviderState,
  action: AlertProviderAction
): AlertProviderState => {
  switch (action.type) {
    case AlertProviderActionType.ADD_MESSAGE:
      return { ...state, messages: [...state.messages, { ...action.item, uid: uuidv4() }] }
    case AlertProviderActionType.REMOVE_ALERT:
      return removeAlert(state, action)
    default:
      return state
  }
}

const removeAlert = (
  state: AlertProviderState,
  action: AlertProviderAction
): AlertProviderState => {
  const updatedMessages = []
  for (const item of state.messages) {
    if (item.uid !== action.itemId) {
      updatedMessages.push(item)
    }
  }
  return { ...state, messages: updatedMessages }
}

import { Button, Form, InputGroup, Modal } from 'react-bootstrap'
import { FileModel, FileType } from '../../../../app/custom/_models'
import clsx from 'clsx'
import { getFileType } from '../../../../app/services/chatbot.service'
import React, { useEffect } from 'react'
import { ChatbotManagerContext } from '../../../../app/custom/chatbots/util'

type SelectedFileModelProps = {
  showModalFile: boolean
  setShowModalFile: Function
  selectedFiles: { [key: string]: any }
  clickSelectFile: Function
  handleSaveChanges: Function
  resetSelectedFiles?: Function
  clickSelectAllFile:Function
  
}

const SelectedFilesModal: React.FC<SelectedFileModelProps> = ({
  showModalFile,
  setShowModalFile,
  selectedFiles,
  clickSelectFile,
  handleSaveChanges,
  resetSelectedFiles,
  clickSelectAllFile,
}) => {
  const { socketRef } = React.useContext(ChatbotManagerContext)
  const [files, setFiles] = React.useState<{ [key: string]: FileModel }>({})
  const [searchQuery, setSearchQuery] = React.useState<string>('')
  const timeoutRef = React.useRef<any>(undefined)
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);

  React.useEffect(() => {
    const onFileItem = (data: FileModel) => {
      setFiles((prev) => {
        return { ...prev, [data.id]: data }
      })
    }
    socketRef!.current!.on('file-item', onFileItem)
    socketRef!.current!.emit('file-list')
    return () => {
      socketRef!.current!.off('file-item', onFileItem)
    }
  }, [])

  // backend search
  // const handleChangeSearch = (value: string) => {
  //   clearTimeout(timeoutRef.current)
  //   timeoutRef.current = setTimeout(() => {
  //     setFiles({})
  //     socketRef!.current!.emit('file-list', { query: value })
  //   }, 300)
  // }

  // frontend search
  const handleChangeSearch = (value: string) => {
    clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      setSearchQuery(value)
    }, 100)
  }

  return (
    <Modal show={showModalFile} centered={true} size='lg' onHide={() => setShowModalFile(false)}>
      <Modal.Header closeButton>
        <div className='d-flex align-items-center justify-content-between w-100'>
          <Modal.Title>
            <div>Select File</div>
          </Modal.Title>
          <div className="d-flex">
            <div>
              <Button variant='secondary' 
              onClick={() =>  clickSelectAllFile(Object.values(files).filter((file: FileModel) =>file.file_name.toLowerCase().includes(searchQuery.toLowerCase())))} 
              className='me-2' 
              disabled={!(Object.keys(files).length > 0?(Object.values(files)
              .filter((file: FileModel) =>
                file.file_name.toLowerCase().includes(searchQuery.toLowerCase())
              ).length>0):(false))}>
                Select All
              </Button>
            </div>
            <div>
              <InputGroup className=''>
                <Form.Control
                  placeholder='Search file name'
                  aria-label='Search file name'
                  aria-describedby='searchFileName'
                  onChange={(e: any) => handleChangeSearch(e.target.value)}
                />
                <InputGroup.Text
                  id='searchFileName'
                  onChange={(e: any) => handleChangeSearch(e.target.value)}
                >
                  <i className='bi bi-search'></i>
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className='row mh-350px mh-lg-500px overflow-y-scroll'>
          {Object.keys(files).length > 0 ? (
            Object.values(files)
              .filter((file: FileModel) =>
                file.file_name.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .map((file: FileModel) => (
                <div
                  key={file.id}
                  className='col-6 col-md-4 my-2 cursor-pointer'
                  onClick={() => clickSelectFile(file)}
                >
                  <div
                    className={clsx(
                      'p-3 rounded d-flex h-md-30px h-lg-50px h-md-30px h-lg-50px align-items-center border',
                      !selectedFiles.hasOwnProperty(file.id)
                        ? 'bg-hover-light-primary'
                        : 'bg-light-primary text-success-invert bg-hover-secondary'
                    )}
                  >
                    {getFileType(file.file_name) === FileType.WORD ? (
                      <i className='fs-2 bi bi-file-word-fill text-primary'></i>
                    ) : getFileType(file.file_name) === FileType.PDF ? (
                      <i className='fs-2 bi bi-file-earmark-pdf-fill text-danger'></i>
                    ) : getFileType(file.file_name) === FileType.EXCEL ? (
                      <i className='fs-2 bi bi-file-earmark-excel-fill text-success'></i>
                    ) : (
                      <i className='fs-2 bi bi-file-earmark-text-fill text-info'></i>
                    )}
                    <div className='text-truncate ms-2'>{file.file_name}</div>
                  </div>
                </div>
              ))
          ) : (
            <div>No file to selected</div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='d-flex justify-content-between align-items-center w-100'>
          {Object.keys(selectedFiles).length > 0 ? (
            <Modal.Title>{Object.keys(selectedFiles).length} files</Modal.Title>
          ) : (
            <Modal.Title>{Object.keys(selectedFiles).length} file</Modal.Title>
          )}
          <div></div>
          <div>
            {resetSelectedFiles ? (
              <Button
                variant='secondary'
                onClick={() => {
                  resetSelectedFiles()
                  setShowModalFile(false)
                }}
                className='me-2'
              >
                Reset
              </Button>
            ) : (
              <Button variant='secondary' onClick={() => setShowModalFile(false)} className='me-2'>
                Close
              </Button>
            )}
            <Button
              variant='primary'
              onClick={() => {
                handleSaveChanges()
                setShowModalFile(false)
              }}
            >
              Save Changes
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export const SelectedFilesModalMemo = React.memo(SelectedFilesModal) // memo is used to prevent re-rendering of component unless props change
// memo can pass the second argument as a function that tells React how to compare the props to determine whether the component should update

/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { KTIcon } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-8'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
      />
      <SidebarMenuItem
        to='/chatbot'
        icon='element-12'
        title={intl.formatMessage({ id: 'MENU.CHATBOT_MANAGER' })}
      />
      {/* <SidebarMenuItem
        to='/playground'
        icon='element-11'
        title='Playground'
      /> */}
      {/* <SidebarMenuItem
        to='/knowledgesource'
        icon='element-12'
        title={intl.formatMessage({id: 'MENU.KNOWLEDGE_SOURCE'})}
      /> */}
      {/* <SidebarMenuItem
        to='/files'
        icon='abstract-24'
        title={intl.formatMessage({ id: 'MENU.FILE_MANAGER' })}
      />
      <SidebarMenuItem
        to='/api-keys'
        icon='setting-2'
        title={intl.formatMessage({ id: 'MENU.API_KEY' })}
      /> */}
      {/* <SidebarMenuItem
        to='/chat'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.CHAT'})}
      /> */}
    </>
  )
}

export { SidebarMenuMain }

import React from 'react'

export const ErrorPage: React.FC<{ error: string }> = ({ error }) => {
  return (
    <div className='d-flex flex-column align-items-center justify-content-center w-100 h-100'>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred</p>
      <p>
        <i>{error}</i>
      </p>
    </div>
  )
}

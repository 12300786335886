import React from 'react'
import { Navigate, Outlet, useNavigate, useParams } from 'react-router-dom'
import { ChatbotManagerContext, ChatbotManagerActionType } from '../util'
import {
  ChatbotDetailContext,
  initChatbotDetailState,
  chatbotDetailReducer,
  ChatbotDetailActionType,
} from './util'
import { ConversationList } from './ConversationList'
import TopBarProgress from 'react-topbar-progress-indicator'

export const ChatbotDetail = () => {
  const navigate = useNavigate()
  const { chatbotId, conversationId } = useParams()
  const { socketRef, bots } = React.useContext(ChatbotManagerContext)
  const [state, dispatch] = React.useReducer(chatbotDetailReducer, initChatbotDetailState)

  // derivatives of context variables
  const bot = chatbotId ? bots[chatbotId] : null

  React.useEffect(() => {
    const onListConversationReady = (data: any) => {
      dispatch({
        type: ChatbotDetailActionType.LIST_CONVERSATION_READY,
        conversations: data,
      })
    }
    const onConversationItem = (data: any) => {
      dispatch({
        type: ChatbotDetailActionType.CONVERSATION_ITEM,
        item: data,
      })
    }
    socketRef!.current!.on('list-conversation-ready', onListConversationReady)
    socketRef!.current!.on('conversation-item', onConversationItem)

    return () => {
      socketRef!.current!.off('list-converation-ready', onListConversationReady)
      socketRef!.current!.off('conversation-item', onConversationItem)
    }
  }, [])

  React.useEffect(() => {
    dispatch({
      type: ChatbotDetailActionType.SET_LOADING_ON,
    })
    socketRef!.current!.emit('conversation-list', { chatbot_id: chatbotId })
  }, [chatbotId])

  React.useEffect(() => {
    if (state.loading === false) {
      if (!conversationId) {
        if (Object.keys(state.conversations).length === 0) {
          navigate('not_found', { replace: true })
        } else {
          navigate(`${Object.values(state.conversations)[0].id}`, { replace: true })
        }
      }
    }
  }, [state.loading])

  if (bot == null)
    return (
      <Navigate
        to={`/chatbot/not_found`}
        replace={true}
        state={{ error: `Chatbot ${chatbotId} not found` }}
      />
    )
  return (
    <ChatbotDetailContext.Provider
      value={{
        loading: state.loading,
        conversations: state.conversations,
        dispatch,
      }}
    >
      {state.loading ? (
        <TopBarProgress />
      ) : (
        <div className='row chat-content'>
          <ConversationList />
          <Outlet />
          {/* nest elements */}
        </div>
      )}
    </ChatbotDetailContext.Provider>
  )
}

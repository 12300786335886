import React from 'react'
import { AlertProviderAction, AlertProviderState, initAlertProviderState } from './reducer'

type AlertProviderContextProps = {
    state: AlertProviderState
    dispatch: React.Dispatch<AlertProviderAction>
}

const initAlertProviderContextProps: AlertProviderContextProps = {
    state: initAlertProviderState,
    dispatch: () => {}
}

export const AlertProviderContext = React.createContext<AlertProviderContextProps>(initAlertProviderContextProps)

import axios from 'axios'
import { API_URL } from '../const'
import { InfoUser } from '../modules/accounts/components/settings/cards/BasicProfile'

export const getInfo = async (): Promise<InfoUser> => {
  const { data } = await axios.get(`${API_URL}/me`)
  return data
}

export const updateInfo = async (
  info: Partial<InfoUser & { password: string; old_password: string }>
): Promise<InfoUser> => {
  const formData = new FormData()
  if (info.password && info.old_password) {
    formData.append('password', info.password)
    formData.append('old_password', info.old_password)
  } else {
    for (const [key, value] of Object.entries(info)) {
      formData.append(key, value as string)
    }
  }
  const { data } = await axios.patch(`${API_URL}/me`, info)
  return data
}

export const googleLogin = async (code: string) => {
  const formData = new FormData()
  formData.append('code', code)
  const { data } = await axios.post(`${API_URL}/google/login/custom`, formData)
  console.log(data)
  return data
}

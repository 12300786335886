import { useContext, useEffect, useState } from 'react'
import { KTIcon } from '../../../_metronic/helpers'
import { ApiKey } from '../../custom/_models'
import { LLMType } from '../../custom/chatbots/util'
import { Modal } from 'react-bootstrap'
import { Formik, FormikHelpers, FormikValues } from 'formik'
import { createApiKey, deleteApiKey, getApiKeys } from '../../services/api-key.service'
import { AlertProviderContext } from '../../custom/alert/context'
import { AlertProviderActionType } from '../../custom/alert/reducer'
import { AlertMessageType } from '../../custom/alert/_models'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'

export const ApiKeyManagement = () => {
  const intl = useIntl()

  const [apiKeys, setApiKeys] = useState([])

  const [showModalCreate, setShowModalCreate] = useState(false)
  const { dispatch: alertDispatch } = useContext(AlertProviderContext)

  const initValues: Partial<ApiKey> = {
    name: '',
    llm_type: LLMType.ChatGPT,
    key: '',
  }

  useEffect(() => {
    getApiKeysAndSet()
  }, [])

  const getApiKeysAndSet = () => {
    getApiKeys().then(({ data }) => {
      setApiKeys(data)
    })
  }

  const deleteAApiKey = (id: string) => {
    console.log(id, 'id')
    deleteApiKey(id).then(() => {
      getApiKeysAndSet()
    })
  }

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'MENU.API_KEY' })}</PageTitle>

      <div className='card h-100'>
        <div className='card-body pt-5 py-3'>
          <div className='d-flex flex-stack justify-content-between'>
            <div className='badge badge-lg badge-light-primary'>
              <div className='d-flex align-items-center flex-wrap'>
                <i className='ki-duotone ki-abstract-32 fs-2 text-primary me-3'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>{' '}
                <a>Chatbot</a>
                <i className='ki-duotone ki-right fs-2 text-primary mx-1'></i>
                <a>API key</a>
              </div>
            </div>
            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-light-primary'
                onClick={() => setShowModalCreate(true)}
              >
                <KTIcon iconName='plus' icon-className='fs-3' />
                Create API Key
              </button>
            </div>

            {/* <div className='badge badge-lg badge-primary'>
              <span id='kt_file_manager_items_counter'>12 mục</span>
            </div> */}
          </div>

          <div className='table-responsive'>
            <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='ps-4 min-w-100px rounded-start'>Name</th>
                  <th className='min-w-200px'>Key</th>
                  <th className='min-w-100px'>LLM Type</th>
                  <th className='min-w-100px'>Expiration date</th>
                  <th className='min-w-100px'>Status</th>
                  <th className='min-w-75px text-end rounded-end'>Actions</th>
                </tr>
              </thead>
              {apiKeys.map((apiKey: ApiKey) => (
                <tr>
                  <td>
                    <div className='d-flex align-items-center'>
                      <a className='text-dark fw-bold text-hover-primary ms-4 mb-1 fs-6'>
                        {apiKey.name}
                      </a>
                    </div>
                  </td>
                  <td>
                    <span className='fw-semibold text-gray-600'>{apiKey.key}</span>
                  </td>

                  <td>
                    <span className='fw-semibold text-gray-600'>
                      {apiKey.llm_type === LLMType.ChatGPT
                        ? 'Chat GPT'
                        : apiKey.llm_type === LLMType.Gemini
                        ? 'Gemini'
                        : 'CMC'}
                    </span>
                  </td>
                  <td>
                    <span className='fw-semibold text-gray-600'>
                      {apiKey.expired_at?.toISOString()}
                    </span>
                  </td>
                  <td>
                    <span className='fw-semibold text-gray-600'>
                      <span className='badge badge-light-success'>
                        {apiKey.status ? 'Hoạt động' : 'Hết hạn'}
                      </span>
                    </span>
                  </td>
                  <td className='text-end'>
                    <button className='btn btn-color-gray-400 btn-active-color-primary btn-icon btn-sm btn-outline-light'>
                      <i className='ki-solid ki-copy fs-2'></i>
                    </button>
                    <button
                      type='button'
                      className='btn btn-color-gray-400 btn-active-color-primary btn-icon btn-sm btn-outline-light'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                    >
                      <i className='bi bi-three-dots-vertical fs-3'></i>
                    </button>
                    <div
                      className='menu menu-sub menu-sub-dropdown  menu-column menu-rounded menu-gray-800 menu-state bg-white fw-semobold py-4 w-150px fs-6 shadow-sm'
                      data-kt-menu='true'
                    >
                      <div
                        className='menu-item px-5 my-1'
                        onClick={() => deleteAApiKey(apiKey.id.toString())}
                      >
                        <a className='menu-link text-danger px-5'>
                          <span className='menu-title text-danger'>Xóa</span>
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>

      <div className='modal show' style={{ display: 'block', position: 'initial' }}>
        <Modal show={showModalCreate} centered onHide={() => setShowModalCreate(false)}>
          <Modal.Header closeButton>
            <div>
              <Modal.Title>Create Api Key</Modal.Title>
              {/* <div className={clsx({ 'text-danger': restCreation === 0 })}>
                {restCreation} creation left
              </div> */}
            </div>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={initValues}
              validate={(values) => {
                const errors: any = {}
                if (values.name === '') {
                  errors.name = `Api key 's name is required`
                }
                if (values.key === '') {
                  errors.key = `Api key 's key is required`
                }
                return errors
              }}
              onSubmit={function (
                values: FormikValues,
                formikHelpers: FormikHelpers<FormikValues>
              ): void | Promise<any> {
                formikHelpers.setSubmitting(false)
                setShowModalCreate(false)
                const apiKey: Partial<ApiKey> = {
                  name: values.name,
                  llm_type: values.llm_type,
                  key: values.key,
                }
                createApiKey(apiKey)
                  .then((data) => {
                    // alert success
                    alertDispatch({
                      type: AlertProviderActionType.ADD_MESSAGE,
                      item: {
                        type: AlertMessageType.SUCCESS,
                        message: 'Successfully created api key',
                      },
                    })

                    getApiKeysAndSet()
                  })
                  .catch((err) => {
                    // alert failed
                    alertDispatch({
                      type: AlertProviderActionType.ADD_MESSAGE,
                      item: {
                        type: AlertMessageType.FAILED,
                        message: `${err}`,
                      },
                    })
                  })
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className='px-7 py-5'>
                    <div className='mb-10'>
                      <label className='form-label fw-bold'>Name:</label>
                      <div>
                        <input
                          type='text'
                          name='name'
                          className='form-control form-control-solid'
                          value={values.name}
                          onChange={handleChange}
                        />
                      </div>
                      {touched.name && errors.name && (
                        <div className='fv-plugins-message-container'>
                          <span role='alert' className='text-danger'>
                            {errors.name}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className='mb-10'>
                      <label className='form-label fw-bold'>LLM Type:</label>
                      <div>
                        <select
                          name='llm_type'
                          className='form-control form-control-solid'
                          value={values.llm_type}
                          onChange={handleChange}
                        >
                          <option value={LLMType.ChatGPT}>Chat GPT</option>
                          <option value={LLMType.Gemini}>Gemini</option>
                          <option value={LLMType.CMC}>CMC</option>
                        </select>
                      </div>
                    </div>

                    <div className='mb-10'>
                      <label className='form-label fw-bold ms-2'>Key:</label>
                      <textarea
                        className='form-control form-control-solid'
                        name='key'
                        onChange={handleChange}
                      />
                    </div>

                    <div className='d-flex justify-content-end'>
                      <button
                        type='submit'
                        className='btn btn-sm btn-primary'
                        data-kt-menu-dismiss='true'
                        disabled={isSubmitting}
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

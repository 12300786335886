import clsx from 'clsx'
import React from 'react'
import { MessageList } from './MessageList'
import {
  ConversationDetailContext,
  ConversationDetailActionType,
} from './util'
import { References } from './References'
import { useParams } from 'react-router-dom'
import { ChatbotManagerContext } from '../util'

export const MessageController: React.FC = () => {
  const { conversationId } = useParams()
  const { state, dispatch } = React.useContext(ConversationDetailContext)
  const { socketRef } = React.useContext(ChatbotManagerContext)
  const messageListRef = React.useRef<HTMLDivElement | null>(null)

  const handleSendMessage = () => {
    if (state.inputMessage === '') return
    socketRef!.current!.emit('save-user-message', {
      conversation_id: conversationId,
      content: state.inputMessage,
    })
  }

  const scrollToBottom = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight
    }
  }

  React.useEffect(() => {
    scrollToBottom()
  }, [state.chatStatus, state.runningMessage])

  React.useEffect(() => {
    const timeout = setTimeout(scrollToBottom, 50)
    return () => clearTimeout(timeout)
  }, [state.references, state.messages.length])

  return (
    <>
      <div
        className='card-body py-0 flex-grow-1 overflow-y-scroll'
        style={{ minHeight: 0 }}
        ref={messageListRef}
      >
        <div className={clsx('me-n5 pe-5')}>
          <MessageList />
          <References />
        </div>
      </div>
      <div className='d-flex card-footer pt-4'>
        <textarea
          className='form-control form-control-flush mb-3'
          rows={1}
          placeholder='Type a message'
          value={state.inputMessage}
          onChange={(e) =>
            dispatch({
              type: ConversationDetailActionType.SET_INPUT_MESSAGE,
              inputMessage: e.target.value,
            })
          }
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault()
              handleSendMessage()
            }
          }}
        ></textarea>
        <button className='btn btn-primary' type='button' onClick={handleSendMessage} style={{borderRadius: '20px', whiteSpace: 'nowrap', height:'fit-content'}}>
          Send <i className="fas fa-chevron-right"></i>
        </button>
        {/* <div className='d-flex flex-stack'>
          <div className='d-flex align-items-center me-2'>
            <button
              className='btn btn-sm btn-icon btn-active-light-primary me-1'
              type='button'
              data-bs-toggle='tooltip'
              title='Coming soon'
            >
              <i className='bi bi-paperclip fs-3'></i>
            </button>
            <button
              className='btn btn-sm btn-icon btn-active-light-primary me-1'
              type='button'
              data-bs-toggle='tooltip'
              title='Coming soon'
            >
              <i className='bi bi-upload fs-3'></i>
            </button>
          </div>
          <button className='btn btn-primary' type='button' onClick={handleSendMessage}>
            Send
          </button>
        </div> */}
      </div>
    </>
  )
}

import { FC } from 'react'
import { FileManager } from './FileManager'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'

export const FileManagerWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'MENU.FILE_MANAGER' })}</PageTitle>
      <FileManager />
    </>
  )
}

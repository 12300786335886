import React from 'react'
import { ErrorPage } from '../../partials/ErrorPage'
import { Navigate, useLocation } from 'react-router-dom'

export const NoChatbot: React.FC = () => {
  const { state } = useLocation()
  const castState: any = state
  const hasPassedState = !!(castState?.error)
  const error = castState?.error ? castState.error : 'Chatbot not found'
  if (hasPassedState) {
    window.history.replaceState({}, "")
  }
  return <ErrorPage error={error} />
}

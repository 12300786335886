import { HighlightArea } from '@react-pdf-viewer/highlight'
import { LLMType } from './chatbots/util'

export type FileModel = {
  id: number | string
  random_id: number | string
  file_name: string
  summary?: string
  status: FileStatus
  progress?: number
  total?: number
  error?: string
  updated_at?: string
  partial?: boolean
  title?: string
  file_type?: string
  description?: string
}

export enum FileStatus {
  COMPLETED = 'COMPLETED',
  SUMMARIZE_FAILED = 'SUMMARIZE_FAILED',
  UPLOADING = 'UPLOADING',
  PARSING = 'PARSING',
  CONVERTING = 'CONVERTING',
  FAILED = 'FAILED',
  INDEXING = 'INDEXING',
  PROCESSING = 'PROCESSING',
}

export type ChatbotModel = {
  id: number | string
  bot_name: string
  system_prompt: string
  use_system_prompt: boolean
  language?: 'vi' | 'en' | 'auto' | null
  description?: string
  chatbot_relation?: any
}

export type BotConnectModel = {
  id: number
  bot_name: string
  channel: 'Telegram' | 'Skype' | null
  bot_token: string
}

export type ConversationModel = {
  id: number | string
  conversation_name?: string
  llm_type?: LLMType
  created_at?: Date
  updated_at?: Date
}

export enum MessageRole {
  USER = 'user',
  ASSISTANT = 'assistant',
}

export enum Reaction {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  NONE = 'none',
}

export type Vote = {
  reaction: Reaction
  answer: string | number | null
  question: string | number | null
  reference: string | number | null
}

export type MessageModel = {
  id: number | string
  content: string
  role: MessageRole
  references?: ReferenceModel[]
  vote?: Vote
  created_at?: Date
  updated_at?: Date
}

export enum FileType {
  WORD = 'WORD',
  EXCEL = 'EXCEL',
  PDF = 'PDF',
}

export type ExtendedHighlightArea = HighlightArea & {
  text: string
}

export type ReferenceModel = {
  id: number | string
  fileId: number | string
  highlights: ExtendedHighlightArea[]
  can_highlight: boolean
  text: string
  vote?: Vote
  file?: FileModel
}

export enum EmbeddingType {
  DPR = 'DPR',
  OPENAI = 'OPENAI',
  MISTRAL= 'MISTRAL'
}

export const EmbeddingTypeDisplayMap = new Map([
  [EmbeddingType.DPR, 'DPR'],
  [EmbeddingType.OPENAI, 'OpenAI'],
  [EmbeddingType.MISTRAL, 'MISTRAL'],
])
enum ApiKeyStatus {
  ACTIVE = 1,
  REVOKED = 0,
}

export type ApiKey = {
  id: number | string
  name: string
  llm_type: LLMType
  created_at: Date
  expired_at: Date
  key: string
  status: ApiKeyStatus
}

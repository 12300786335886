export enum AlertMessageType {
    SUCCESS = 'SUCCESS',
    WARNING = 'WARNING',
    FAILED = 'FAILED'
}

export type AlertMessage = {
    uid: string
    message: string
    type: AlertMessageType
}
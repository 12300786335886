import axios from 'axios'
import { API_URL } from '../const'
import { BotConnectModel, ChatbotModel, FileType } from '../custom/_models'
const CHATBOT_URL = `${API_URL}/chatbot`

export const ChatbotService = 'Chatbot service' // test
// write api call chatbot in here

export function getFileType(fileName: string): string | null {
  const lowerCaseFileName = fileName.toLowerCase()

  if (lowerCaseFileName.endsWith('.doc') || lowerCaseFileName.endsWith('.docx')) {
    return FileType.WORD
  } else if (lowerCaseFileName.endsWith('.xls') || lowerCaseFileName.endsWith('.xlsx')) {
    return FileType.EXCEL
  } else if (lowerCaseFileName.endsWith('.pdf')) {
    return FileType.PDF
  } else {
    return null // Unknown file type
  }
}

export const deleteChatbot = async (chatbotId: number) => {
  const endpoint = `${CHATBOT_URL}/${chatbotId}`
  const { data } = await axios.delete(endpoint)
  return data
}

export const getListChatbots = async (
  bot_name: string,
  system_prompt: string,
  language: string,
  files: string
) => {
  const params = {
    bot_name: bot_name.length > 0 ? bot_name : null,
    system_prompt: system_prompt.length > 0 ? system_prompt : null,
    language: language.length > 0 ? language : null,
    files: files.length > 0 ? files : null,
  }
  const { data } = await axios.get(CHATBOT_URL, { params })
  return data
}

export const updateChatbot = async (chatbotId: number, updateData: Partial<ChatbotModel>) => {
  const formData = new FormData()
  updateData.bot_name && formData.append('bot_name', updateData.bot_name)
  updateData.system_prompt && formData.append('system_prompt', updateData.system_prompt)
  updateData.language && formData.append('language', updateData.language)
  updateData.use_system_prompt != null && formData.append('use_system_prompt', updateData.use_system_prompt.toString())

  const endpoint = `${CHATBOT_URL}/${chatbotId}`
  const { data } = await axios.patch(endpoint, formData)
  return data
}

export const connectChatbot = async (chatbotId: number, connectData: Partial<BotConnectModel>) => {
  const formData = new FormData()
  connectData.bot_name && formData.append('chatbot', chatbotId.toString())
  connectData.channel && formData.append('channel', connectData.channel)
  connectData.bot_token && formData.append('bot_token', connectData.bot_token)

  const endpoint = `${API_URL}/connect-bot`
  const { data } = await axios.post(endpoint, formData)
  return data
}
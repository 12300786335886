import axios from 'axios'
import { API_URL } from '../const'
import { ApiKey } from '../custom/_models'
const API_KEY_URL = `${API_URL}/api-key`

export const createApiKey = async (data: Partial<ApiKey>) => {
  return await axios.post(API_KEY_URL, data)
}

export const getApiKeys = async () => {
  return await axios.get(API_KEY_URL)
}

export const deleteApiKey = async (id: number | string) => {
  return await axios.delete(`${API_KEY_URL}/${id}`)
}

import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { EmbeddingType } from '../../_models'

export type SelectedFile = {
  randomId: string
  file: File
  embType: EmbeddingType
  title: string
  file_type: string
  description: string
}

export type UploaderState = {
  selectedFiles: {
    [key: string]: SelectedFile
  }
}

export const initUploaderState: UploaderState = { selectedFiles: {} }

export enum UploaderActionType {
  ADD_FILES = 'ADD_FILES',
  REMOVE_FILE = 'REMOVE_FILE',
  RESET_SELECTED_FILES = 'RESET_SELECTED_FILES',
  UPDATE_EMBEDDING_TYPE = 'UPDATE_EMBEDDING_TYPE',
  UPDATE_METADATA_TITLE = 'UPDATE_METADATA_TITLE',
  UPDATE_METADATA_TYPE = 'UPDATE_METADATA_TYPE',
  UPDATE_METADATA_DESCRIPTION = 'UPDATE_METADATA_DESCRIPTION',
}

export type UploaderAction = {
  type: UploaderActionType
} & { [key: string]: any }

export const uploaderReducer = (state: UploaderState, action: UploaderAction): UploaderState => {
  switch (action.type) {
    case UploaderActionType.ADD_FILES:
      return addFiles(state, action)
    case UploaderActionType.REMOVE_FILE:
      return removeFile(state, action)
    case UploaderActionType.RESET_SELECTED_FILES:
      return { selectedFiles: {} }
    case UploaderActionType.UPDATE_EMBEDDING_TYPE:
      return {
        ...state,
        selectedFiles: {
          ...state.selectedFiles,
          [action.fileRandomId]: {
            ...state.selectedFiles[action.fileRandomId],
            embType: action.embType,
          },
        },
      }
    case UploaderActionType.UPDATE_METADATA_TITLE:
      return {
        ...state,
        selectedFiles: {
          ...state.selectedFiles,
          [action.fileRandomId]: {
            ...state.selectedFiles[action.fileRandomId],
            title: action.title,
          },
        },
      }
    case UploaderActionType.UPDATE_METADATA_TYPE:
      return {
        ...state,
        selectedFiles: {
          ...state.selectedFiles,
          [action.fileRandomId]: {
            ...state.selectedFiles[action.fileRandomId],
            file_type: action.file_type,
          },
        },
      }
    case UploaderActionType.UPDATE_METADATA_DESCRIPTION:
      return {
        ...state,
        selectedFiles: {
          ...state.selectedFiles,
          [action.fileRandomId]: {
            ...state.selectedFiles[action.fileRandomId],
            description: action.description,
          },
        },
      }
    default:
      return state
  }
}

const addFiles = (state: UploaderState, action: UploaderAction): UploaderState => {
  const { files } = action
  const selectedFiles = { ...state.selectedFiles }
  for (const f of files) {
    const fileRandomId = uuidv4()
    selectedFiles[fileRandomId] = {
      randomId: fileRandomId,
      file: f,
      embType: EmbeddingType.MISTRAL,
      title: f.name,
      file_type: '',
      description: ''
    }
  }
  return { selectedFiles }
}

const removeFile = (state: UploaderState, action: UploaderAction): UploaderState => {
  const selectedFiles = { ...state.selectedFiles }
  delete selectedFiles[action.fileRandomId]
  return { selectedFiles }
}

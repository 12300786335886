import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { WithChildren } from '../../../../_metronic/helpers'
import { getFileType } from '../../../services/chatbot.service'
import { FileType, EmbeddingType, EmbeddingTypeDisplayMap } from '../../_models'
import { SelectedFile, UploaderAction, UploaderActionType } from './util'

type Props = {
  item: SelectedFile
  onItemRemove: () => void
  uploaderDispatch: React.Dispatch<UploaderAction>
}

export const FileItem: React.FC<Props & WithChildren> = ({
  item,
  onItemRemove,
  uploaderDispatch,
}) => {
  return (
    <div className='col-6 col-md-4 my-2'>
      <div className='p-3 rounded bg-light-primary bg-hover-secondary file-item border-secondary'>
        <div className=' d-flex h-100 flex-row justify-content-between align-items-center'>
          {getFileType(item.file.name) === FileType.WORD ? (
            <i className='fs-2 bi bi-file-word-fill text-primary'></i>
          ) : getFileType(item.file.name) === FileType.PDF ? (
            <i className='fs-2 bi bi-file-earmark-pdf-fill text-danger'></i>
          ) : getFileType(item.file.name) === FileType.EXCEL ? (
            <i className='fs-2 bi bi-file-earmark-excel-fill text-success'></i>
          ) : (
            <i className='fs-2 bi bi-file-earmark-text-fill text-info'></i>
          )}
          <div className='filename-block ms-2'>
            <span title={item.file.name} className='filename-span'>
              {item.file.name}
            </span>
          </div>
          <i className='bi bi-x ms-2 hoverable-icon fs-1' onClick={onItemRemove}></i>
        </div>
        <div className='d-flex ms-1 mt-2'>
          <label className='form-check form-check-sm form-check-custom form-check-border me-5'>
            <input
              className='form-check-input cursor-pointer border-primary'
              style={{ width: '1em', height: '1em' }}
              type='radio'
              name={item.randomId}
              checked={item.embType === EmbeddingType.DPR}
              onChange={(e) => {
                uploaderDispatch({
                  type: UploaderActionType.UPDATE_EMBEDDING_TYPE,
                  fileRandomId: item.randomId,
                  embType: EmbeddingType.DPR,
                })
              }}
            />
            <span className='form-check-label text-gray-700'>DPR</span>
          </label>

          <label className='form-check form-check-sm form-check-custom form-check-border me-5'>
            <input
              className='form-check-input cursor-pointer border-primary'
              style={{ width: '1em', height: '1em' }}
              type='radio'
              name={item.randomId}
              checked={item.embType === EmbeddingType.OPENAI}
              onChange={(e) => {
                uploaderDispatch({
                  type: UploaderActionType.UPDATE_EMBEDDING_TYPE,
                  fileRandomId: item.randomId,
                  embType: EmbeddingType.OPENAI,
                })
              }}
            />
            <span className='form-check-label text-gray-700'>OpenAI</span>
          </label>
          <label className='form-check form-check-sm form-check-custom form-check-border'>
            <input
              className='form-check-input cursor-pointer border-primary'
              style={{ width: '1em', height: '1em' }}
              type='radio'
              name={item.randomId}
              checked={item.embType === EmbeddingType.MISTRAL}
              onChange={(e) => {
                uploaderDispatch({
                  type: UploaderActionType.UPDATE_EMBEDDING_TYPE,
                  fileRandomId: item.randomId,
                  embType: EmbeddingType.MISTRAL,
                })
              }}
            />
            <span className='form-check-label text-gray-700'>Mistral</span>
          </label>
        </div>
        <div className='mb-1'>
          <label className='form-label fw-bold'>Title:</label>
          <div>
            <input type='text' name='title' 
            className='form-control form-control-solid' 
            defaultValue={item.file.name}
            onChange={(e) => {
              uploaderDispatch({
                type: UploaderActionType.UPDATE_METADATA_TITLE,
                fileRandomId: item.randomId,
                title: e.target.value,
              })
            }}/>
          </div>
        </div>
        <div className='mb-1'>
          <label className='form-label fw-bold'>Type:</label>
          <div>
            <input type='text' name='file_type' 
            className='form-control form-control-solid'
            onChange={(e) => {
              uploaderDispatch({
                type: UploaderActionType.UPDATE_METADATA_TYPE,
                fileRandomId: item.randomId,
                file_type: e.target.value,
              })
            }}/>
          </div>
        </div>
        <div className='mb-1'>
          <label className='form-label fw-bold'>Description:</label>
          <div>
            <input type='text' name='description' 
            className='form-control form-control-solid'
            onChange={(e) => {
              uploaderDispatch({
                type: UploaderActionType.UPDATE_METADATA_DESCRIPTION,
                fileRandomId: item.randomId,
                description: e.target.value,
              })
            }}/>
          </div>
        </div>
      </div>
    </div>
  )
}

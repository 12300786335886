import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { ChatbotManager } from './ChatbotManager'
import { ChatbotList } from './chatbot-list/ChatbotList'
import { ChatbotDetail } from './chatbot-detail/ChatbotDetail'
import { ConversationDetail } from './chatbot-detail/ConversationDetail'
import { NoConversation } from './chatbot-detail/NoConversation'
import { NoChatbot } from './chatbot-detail/NoChatbot'

export const ChatbotRoutes: React.FC = () => {
  return (
    <Routes>
      <Route element={<ChatbotManager />}>
        <Route index element={<ChatbotList />} />
        <Route path=':chatbotId' element={<Navigate to='conversation' replace />} />
        <Route path='not_found' element={<NoChatbot />} />
        <Route path=':chatbotId/conversation' element={<ChatbotDetail />}>
          <Route path='not_found' element={<NoConversation />} />
          <Route path=':conversationId' element={<ConversationDetail />} />
        </Route>
        <Route path='*' element={<h1>Not Found</h1>} />
      </Route>
    </Routes>
  )
}
